import {
    Box,
    Button,
    CircularProgress,
    debounce, Grid, LinearProgress, Typography
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useEffect, useMemo, useRef} from 'react';

import { useHistory } from "react-router-dom";
import CsvImport from '../components/csvImport';
import CsvImportResponseDialog from "../components/CsvImportResponseDialog";
import ErrorMessage from "../components/errorMessage";
import ZennyaTheme from "../providers/ZennyaThemeProvider";
import corporateEmployeeService from '../services/corporate-employee.service';
import corporateHealthService from './../services/corporate-health.service';
import reportService from '../services/report.service';
import AddEmployeeDialog from "./AddEmployeeDialog";
import { gridColumns, gridRows } from './employeeData';
import EmployeeGrid from './grid/index';
import { Metrics, PieMetric } from '../events/eventDetail/metrics';
import { EmployeeMetrics } from './employeeMetrics';
import v, { isEmpty } from 'voca'
import { Menu as ContextMenu, Item as ContextItem } from "react-contexify";
import { ListItem } from '@material-ui/core';
import logger, { snackbar } from '../services/logger.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useState } from '../utils/stateref';
import moment from 'moment';
import { generateFirstSecondDose, isVaccinated, isVaccineCompleted } from '../utils/vaccineUtils';

const useStyles = makeStyles(theme => ({
    root: props=>({
        flexGrow: 1,
        padding: props.compact ? theme.spacing(0) : theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 1920
        }
    }),
    officeInfoTitle: {
        padding: 10
    },
    officeInfoSection: {
        padding: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textLeft: {
        textAlign: 'left'
    },
    marginSpacer: {
        marginRight: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        width: '45%',
    },
    linkColor: {
        color: theme.palette.link.main
    },
    btnTextSize: {
        fontSize: '1.25rem'
    },
    rectangularChip: {
        padding: 5,
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '0.75rem'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    headerAction: {
        '& > *': {
            marginLeft: theme.spacing(1)
        }
    }
}));

export const PEOPLE_TYPE = {
    WORKERS: 'Workers',
    DEPENDENTS: 'Dependents',
    THIRDPARTY: 'External Workers'
}
export function getPeopleTypeByValue(value) {
    return Object.keys(PEOPLE_TYPE).find(key => PEOPLE_TYPE[key] === value)
}

function EmployeeGridContainer({peopleType=PEOPLE_TYPE.WORKERS,
    isHealth, 
    hasImportBar=true, compact,
    hasRisk=false, hasPeopleTypeFilter=false,
    riskFilter,
    riskBar,
    vaccineData,
    ...props}) {
    const { enqueueSnackbar } = snackbar()
    const classes = useStyles({compact});
    // const [ company, setCompany, companyRef ] = useState();
    const [ employees, setEmployees, employeesRef ] = useState({
        list: [],
        count: 0
    });
    const allLocations = { id: 0, label: "All Locations" }
    const allDepartments = { id: 0, label: "All Departments" }
    const [ locationSelection, setLocationSelection, locationSelectionRef ] = useState(allLocations)
    const [ locations, setLocations, locationsRef ] = useState([])
    const [ search, setSearch, searchRef ] = useState()
    const [ searchTimer, setSearchTimer, searchTimerRef ] = useState(setTimeout(() => {}))
    const [ searchCounter, setSearchCounter, searchCounterRef ] = useState(0)
    const [ departmentSelection, setDepartmentSelection, departmentSelectionRef ] = useState(allDepartments)
    const [ departments, setDepartments, departmentsRef ] = useState([])
    const [ isInternal, setInternal, isInternalRef ] = useState("")
    const [ sortParams, setSortParams, sortParamsRef ] = useState({ column: null, value: null, changeColumn: '', val: ''});
    const [ isLinked, setLinked, isLinkedRef ] = useState("")
    const [ isLoadingLocations, setLocationsLoader, isLoadingLocationsRef ] = useState(false)
    const [ isLoadingEmployees, setIsLoadingEmployees, isLoadingEmployeesRef ] = useState(false)
    const [ isLoadingDepartments, setDepartmentsLoader, isLoadingDepartmentsRef ] = useState(false)
    const [pageState, setPage, page] = useState(1);
    const [ initialLoading, setInitialLoading, initialLoadingRef ] = useState(false);
    const [selectedRows, setSelectedRows, selectedRowsRef] = useState(() => new Set());
    const [requestParams, setRequestParams, requestParamsRef] = useState({});
    const [resetScroll, setResetScroll, resetScrollRef] = useState(false);

    const [exporting, setExporting, exportingRef] = useState(false);
    const [covidTestResults, setCovidTestResults, covidTestResultsRef] = useState({
        list:[],
        count:0
    });
    const confirm = useConfirm()

    const [ notification, setNotification, notificationRef ] = useState({
        message: '',
        show: false,
        success: false,
        error: false
    })
    const [ params, setParams, paramsRef ] = useState({
        page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
        page_offset: 0
    });
    // const [ page, setPage, pageRef ] = useState(0);

    
    
    const [ importResponseModal, setImportResponseModal, importResponseModalRef ] = useState(false)
    const [ importResponse, setImportResponse, importResponseRef ] = useState({ successCount: 0 })

    // const [employeeList, setEmployeeList, employeeListRef] = useState({list:[], count: 0});

    const employeeList = useRef({
        list:[],
        count:0
    })
    const setEmployeeList = (eref) => {
        employeeList.current = {
            ...eref
        }
    }

    const [columns, setColumns, columnsRef] = useState([]);
    const [filters, setFilters, filtersRef] = useState({});

    const [relationOptions, setRelationOptions, relationOptionsRef] = useState([]);
    const history = useHistory();

    const [importType, setImportType, importTypeRef] = useState();

    
    const setShowNotification = (value) => {
        setNotification({
            ...notification,
            show: value
        })
    }

    const fetchCovidTestResultsReport = useCallback(
        async () => {
            var prms = {
                id: props?.company,
                page_size: 10,
                page_offset: 0
            }
            const response = await reportService.getCovidTestResults(prms)
    
            var newList = response.list.map((r, index) => {
                return {
                    ...r,
                    test_date: moment(r.test_date).format('YYYY/MM/DD hh:mm:ss'),
                    valid_until: moment(r.valid_until).format('YYYY/MM/DD hh:mm:ss')
                }
            });
            return ({
                list: newList,
                count: response.count
            });
        },
        [props?.company],
    );

    useEffect(() => {
        async function getCovidTestResults() {
            const result = await fetchCovidTestResultsReport()
            setCovidTestResults(result)
        }
        getCovidTestResults()
    }, [fetchCovidTestResultsReport, props.company, setCovidTestResults]);


    const [isAllrowsSelected, setIsAllrowsSelected, isAllrowsSelectedRef] = useState(false);

    const onAllRowsSelectionChange = useCallback(
        (rowset) => {
            setIsAllrowsSelected(rowset)
            const empls = new Set()
            if(rowset) {
                (employeeList.current?.list??[]).forEach(element => {
                    empls.add(element.id)
                });
            }
            setSelectedRows(empls)
        },
        [setIsAllrowsSelected, setSelectedRows],
    );
    
    useEffect(()=> {
        setColumns(gridColumns({hasRisk, hasPeopleTypeFilter, isHealth, vaccineData, relationOptions, peopleType, history, onAllRowsSelectionChange, allRowsSelected:isAllrowsSelected}))
    }, [vaccineData, history, isAllrowsSelected, onAllRowsSelectionChange, peopleType, relationOptions, setColumns, hasRisk, hasPeopleTypeFilter, isHealth])
    
    const debouncedFilter = useCallback(
		debounce(filters => {
            const {status, worker_type, search:name, ...accessoryFilters} = filters
            
            let internal = isInternal
			let linked = isLinked
            if (filters?.status === 'Linked') {
                linked = true
            } else if (filters?.status === 'Not Linked') {
                linked = false
            } else if (filters?.status === '' || filters?.status === undefined) {
                linked = null
            }
			setLinked(linked)
            if (filters?.worker_type === 'Third-Party') {
                internal = false
            } else if (filters?.worker_type === 'Internal') {
                internal = true
            } else if (filters?.worker_type === ''  || filters?.worker_type === undefined) {
                internal = null
            }
			setInternal(internal)
            setSearch(filters?.search)
            const newParams = {...params, ...accessoryFilters, page_offset: 0}
            setParams(newParams)
            setPage(1)
            setResetScroll(true)
            getCompanyEmployees(internal, linked, filters?.search, sortParams, newParams, {loadMore: false} );
        }, 500),
		[],
	);

    const handleSort = (params) => {
        setSortParams(params);
        getCompanyEmployees(isInternalRef.current, isLinkedRef.current, searchRef.current, sortParamsRef.current, params.current, {loadMore: false});
    }

    const getCompanyEmployees = useCallback(async (internal, linked, search, sortParams, pageParams, options) => {
        setInitialLoading(false);
        const locationId = locationSelection && locationSelection.id !== 0 ? locationSelection.id : null
        const departmentId = departmentSelection && departmentSelection.id !== 0 ? departmentSelection.id : null
        const req = {
            ...params,
            location_ids: locationId,
            departments: departmentId,
            internal,
            linked,
            query: search,
            sort_by: sortParams?.column,
            sort_order: sortParams?.value,
            ...pageParams
        }
        setRequestParams(req)
        setIsLoadingEmployees(true)

        let fetchFunction
        switch (peopleType) {
            case PEOPLE_TYPE.DEPENDENTS:
                fetchFunction = corporateHealthService.getDependents
                break;
            case PEOPLE_TYPE.THIRDPARTY:
                fetchFunction = corporateHealthService.getThirdPartyEmployees
                break;
            default:
                fetchFunction = corporateHealthService.getPartnerEmployeesAndReferences
                break;
        }
        if (pageParams?.risk_factors) {
            switch (peopleType) {
                case PEOPLE_TYPE.DEPENDENTS:
                    fetchFunction = corporateHealthService.getDependentsWithRisk
                    break;
                case PEOPLE_TYPE.THIRDPARTY:
                    fetchFunction = corporateHealthService.getThirdPartyEmployeesWithRisk
                    break;
                default:
                    fetchFunction = corporateHealthService.getPartnerEmployeesAndReferencesWithRisk
                    break;
            }   
        }

        try {
            const response = await fetchFunction(props.company, req)
            
            const mappedResponse = response?.list?.map(employee => {
                const completion = isVaccineCompleted(employee.vaccines, vaccineData)
                const elem =  {
                    ...employee, 
                    vaccines: employee?.vaccines?.sort((a, b) => {
                        return new Date(b?.doseDate) - new Date(a?.doseDate)
                    }),
                    isVaccineCompleted: completion,
                    isVaccinated: isVaccinated(employee.vaccines),
                    vaccineTimeline:generateFirstSecondDose(employee.vaccines, vaccineData, completion, !!employee?.immuno_status)
                }
                return elem;
            })

            const report = covidTestResultsRef.current
            
            const list = gridRows(mappedResponse ?? [], report?.list ?? [] )
            if (options.loadMore) {
                setEmployeeList({list:[...(employeeList.current?.list??[]), ...list], count: response.count})
            } else {
                setEmployeeList({list, count: response.count})
            }
            setInitialLoading(true);
        }catch (e) {
            console.log("📢[employeeList.js:337]: ", e);
        }
        finally{
            setIsLoadingEmployees(false)
            setResetScroll(false)
        }
        
    }, [covidTestResultsRef, departmentSelection, locationSelection, params, peopleType, props.company, setInitialLoading, setIsLoadingEmployees, setRequestParams, setResetScroll, vaccineData])


    function handleChangeRowsPerPage(event, paginate) {
        const newParams = {
            ...params,
            page_size:paginate?.props?.value,
            page_offset: 0
        }
        setParams(newParams)
        setPage(1)
        setResetScroll(true)
        getCompanyEmployees(isInternal, isLinked, search, sortParams, newParams, {loadMore: false});
    }
    function handleChangePage(event, newPage) {
        const newParams = {
            ...params,
            page_offset: ((newPage + 1) * params.page_size) - params.page_size
        }
        setParams(newParams)
        setPage(newPage);
        getCompanyEmployees(isInternal, isLinked, search, sortParams, newParams, {loadMore: true});
    }

    const loadMorePages = useCallback(
        () => {
            if(isLoadingEmployeesRef.current) return
            setPage(page.current + 1)
            const newParams = {
                ...params,
                page_offset: ((page.current) * params.page_size) - params.page_size
            }
            setParams(newParams)
            getCompanyEmployees(isInternalRef.current, isLinkedRef.current, searchRef.current, sortParamsRef.current, newParams, {loadMore: true});
        },
        [getCompanyEmployees, isInternalRef, isLinkedRef, isLoadingEmployeesRef, page, params, searchRef, setPage, setParams, sortParamsRef],
    );
    

    function importEmployees (file, setModal, setLoader) {
        setLoader(true)

        let params = new FormData()
        params.append('file', file)

        let importFn
        switch (peopleType) {
            case PEOPLE_TYPE.DEPENDENTS:
                // params.append('type', 'DEPENDENT')
                importFn = corporateHealthService.importDependentReferences
                break;
            case PEOPLE_TYPE.THIRDPARTY:
                // params.append('type', 'EXTERNAL')
                importFn = corporateHealthService.importThirdPartyReferences
                break;
            case PEOPLE_TYPE.WORKERS:
            default:
                // params.append('type', 'EMPLOYEE')
                importFn = corporateHealthService.importEmployeeReferences
                break;
        }
        importFn(props.company, params)
            .then(response => {
                getCompanyEmployees(isInternal, isLinked, search, sortParams, params, {loadMore: false});
                setModal(false)

                setImportResponse(
                    {
                        successCount: response.imported_rows_count || 0,
                        errors: response.skipped_rows,
                        warnings: response.warnings
                    }
                )

                setImportResponseModal(true)
            })
            .catch((err) => {
                const message = err?.response?.data?.details || err?.response?.data?.message?.en || 'Error: ' + err?.response?.status
                setNotification({
                    ...notification,
                    message: { message: `${message ? message : "Unable to import CSV"}` },
                    show: true,
                    error: true
                })
            })
            .finally(() => {
                setLoader(false)
            })
    }

    function handleSearch() {
        clearTimeout(searchTimer)

        setSearchCounter(counter => counter + 1)
    }

    useEffect(() => {
        async function fetchRelationOptions() {
            setRelationOptions(((await corporateHealthService.getRelationOptions())?.data?.list??[]).map((i)=>{
                return {
                    label: v.capitalize(i, true),
                    value: i
                }
            }))
        }
        fetchRelationOptions()
    }, []);

    useEffect(() => {
        const getCompanyLocations = () => {
            setLocationsLoader(true)

            corporateHealthService.getPartnerLocations(props.company, {})
                .then(({ list }) => {
                    setLocations([allLocations].concat(list))
                })
                .catch()
                .finally(() => {
                    setLocationsLoader(false)
                })
        }

        const getCompanyDepartments = () => {
            setDepartmentsLoader(true)

            corporateHealthService.getPartnerDepartments(props.company)
                .then(({ list }) => {
                    setDepartments([allDepartments].concat(list))
                })
                .catch()
                .finally(() => {
                    setDepartmentsLoader(false)
                })
        }

        getCompanyLocations();
        getCompanyDepartments()

    }, [props.company]);

    // useEffect(() => {
    //     console.log('ue:', isInternal, isLinked, search)
    //     getCompanyEmployees(isInternal, isLinked, search, sortParams);
        
    // }, [])

	useEffect(() => {
		switch (peopleType) {
            case PEOPLE_TYPE.DEPENDENTS:
                setImportType('DEPENDENT')
                break;
            case PEOPLE_TYPE.THIRDPARTY:
                setImportType('EXTERNAL')
                break
            default:
                break;
        }
	}, [peopleType]);

    const handleAfterSuccess = event => {
        getCompanyEmployees(isInternal, isLinked, search, sortParams, params, {loadMore: false});
    }

    const sendInvitesAll = async () => {
        const unlinkedrefs = await corporateHealthService.getPartnerEmployeesAndReferences(props.company, {linked:false})
    }
    const sendInvites = async (selRowIds) => {
        const result = await corporateEmployeeService.resendInvites(Array.from(selRowIds))
    }

    const renderLeftAccessory = useCallback(
        () => {
            if (isLinked===false || selectedRows.length>0) {
                return (<Button variant="contained" onClick={() => {
                    confirm({
                        title: 'Send invites',
                        description: `Send email invites to selected ${peopleType.toLowerCase()}?`,
                        cancellationButtonProps: {autoFocus: true}
                    })
                    .then(()=>sendInvites(selectedRows))
                    .catch(() => {})
                }} color="primary">Resend Invites to Selected ({selectedRows.length})</Button>)
            }
            return (<></>)
        },
        [confirm, isLinked, peopleType, selectedRows],
    );

    const requiredFields = useMemo(() => {
        let fields = ['First Name','Last Name', 'Email', 
        // 'Mobile' //todo: remove on lift
        ]
        switch (peopleType) {
            case PEOPLE_TYPE.WORKERS:
                return fields
            case PEOPLE_TYPE.DEPENDENTS:
                return [...fields, 'Company ID of employee', 'Relationship to employee']
            case PEOPLE_TYPE.THIRDPARTY:
                return [...fields, 'Third-Party Employer', 'Company ID']
            default:
                return fields
        }

    }
    , [peopleType]);
    function copyRow({ props: { row } }) {
        const rowcsv = Object.entries(row).map(([k, v]) => {
            return `"${v}"`;
        }).join(",");
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute("value", rowcsv);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        enqueueSnackbar(`copied to clipboard`);
    }
    function copyCell({props}) {
        const { row } = props;
        const rowcsv = row?.company_id
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute("value", rowcsv);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        enqueueSnackbar(`copied to clipboard`);
    }

    const doExport = async () => {
        setExporting(true)
        const result = await corporateHealthService.getPeopleListCSV(props.company, peopleType, requestParams, isHealth)
        if (!isEmpty(result)) {
            logger.err(enqueueSnackbar, result)
        }
        setExporting(false)
    }
    return (
        <ThemeProvider theme={ZennyaTheme}>
            <div className={classes.root}>
                { notification.error && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={notification.message} /> }
                <CsvImportResponseDialog
					modal={importResponseModal}
					setModal={setImportResponseModal}
					successCount={importResponse.successCount}
					errors={importResponse.errors}
					warnings={importResponse.warnings}
				/>
                {hasImportBar &&
				<Box className={classes.header}>
                    <Typography variant="h5" className={classes.textLeft} style={{ fontWeight: 'bold' }}>
                        {peopleType} List
                    </Typography>
                    <div className={classes.headerAction}>
                        <AddEmployeeDialog
                            peopleType={peopleType}
                            partnerId={props.company}
                            addEmployeeCallback={getCompanyEmployees}
                        />
                        <CsvImport
                            title={`Bulk Import ${peopleType}`}
                            // downloadUrl={'https://dnjqko642wsuu.cloudfront.net/files/employee_bulk_import_template_vax.tsv'}
                            downloadUrl={'/api/1/corporate/download_import_template_csv'}
                            downloadParams={{type:importType}}
                            fileName={'Bulk Worker Import Template.csv'}
                            subtitle={"Please add your detailed worker list in."}
                            instructionMessage={"Before uploading the worker list, please make sure that the file has completed the ff columns:"}
                            requiredFields={requiredFields}
                            buttonText={`Bulk Import ${peopleType}`}
                            action={importEmployees}
                        />
                    </div>
				</Box>
                }
				{props.children}
                <Grid container>
                    <Grid item xs={12} style={{textAlign:'left'}}>
                            <EmployeeGrid
                                riskFilter={riskFilter}
                                columns={columns}
                                company={props.company} 
                                listCall={handleAfterSuccess}
                                onChangePage={handleChangePage}
                                handleFilterChange={debouncedFilter}
                                handleSort={handleSort}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                rows={employeeList.current?.list || [] }
                                params={params}
                                page={page.current}
                                count={employeeList.current?.count}
                                totalCount={employeeList.current?.count}
                                leftAccessory={()=> {
                                    return renderLeftAccessory()
                                    
                                }}
                                centerAccessory={()=> riskBar}
                                rightAccessory={()=> 
                                    <Button
                                        onClick={() => doExport()}
                                        variant="text" color="textSecondary" disabled={exporting}
                                        startIcon={exporting ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faFileExport} />}
                                    >Export</Button>
                                }
                                onSelectedRows={(selRows)=>{
                                    setSelectedRows(selRows)
                                }}
                                contextMenu={{
                                    menuId: 'EmployeeGridContext',
                                    contextItems(props) {
                                        return (
                                            <ContextMenu id={"EmployeeGridContext"}>
                                                <ContextItem onClick={copyRow}>
                                                    <ListItem>Copy row to clipboard</ListItem>
                                                </ContextItem>
                                                <ContextItem onClick={copyCell}>
                                                    <ListItem>Copy Company Id to clipboard</ListItem>
                                                </ContextItem>
                                            </ContextMenu>
                                        )
                                    }
                                }}
                                selectedRows={selectedRows}
								loading={!initialLoading}
                                resetScroll={resetScrollRef.current}
                                onLoadMore={()=>{
                                    loadMorePages(null, props.page+1)
                                }}
                                disablePagination
                            />
                        
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}

export default function Employees({peopleType=PEOPLE_TYPE.WORKERS, isHealth=false, hasMetrics=true, ...props}){
    const [vaccineData, setVaccineData] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function fetchVaccineData() {
            const result = await corporateHealthService.getVaccines()
            setVaccineData(result?.data?.list)
            setLoading(false)
        }
        fetchVaccineData()
    }, []);

    return loading ? <LinearProgress/> : (
            <EmployeeGridContainer vaccineData={vaccineData} peopleType={peopleType} {...props} isHealth={isHealth}>
                {hasMetrics && (
                    <Box paddingY={2}>
                        <Grid container spacing={2}>
                            <EmployeeMetrics peopleType={peopleType} partnerId={props.company} isHealth={isHealth}/>
                        </Grid>
                    </Box>
                )}
			</EmployeeGridContainer>
    )

}

const Dependents = (props) => {
    return <Employees peopleType={PEOPLE_TYPE.DEPENDENTS} {...props} />
}
const ThirdParty = (props) => {
    return <Employees peopleType={PEOPLE_TYPE.THIRDPARTY} {...props} />
}

export {Dependents, ThirdParty}

