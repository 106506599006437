import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Hidden, Divider, Drawer, Paper } from '@material-ui/core';
import {
  Category as CategoryIcon,
  GroupWork,
  Settings as SettingsIcon
} from "@material-ui/icons"
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import Link from '@material-ui/icons/Link';
import { AppBar, Toolbar, IconButton, Typography, Grid } from '@material-ui/core';

import Profile from './Profile';
import SidebarNav from './SidebarNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUserFriends, faHeartbeat, faCalendar, faLink, faViruses, faStarOfLife, faPercent, faSitemap, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import zennya_logo from './../assets/images/zennya-icon-text.png';
import TopBar from './Topbar';
import { UserContext, UserProvider } from '../context/UserContext';


const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
  _drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    },
    backgroundColor: '#465A6D'
  },
  root: {
    color: 'white',
    backgroundColor: '#465A6D',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    // marginBottom: theme.spacing(2)
  },
  defaultIconSize: {
    width: 22,
    height: 22
  },
  sidebarContainer: {
    backgroundColor: '#465A6D'
  },
  // root: {
  //   display: 'flex',
  //   backgroundColor: '#465A6D',
  // },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: '#fff'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#465A6D'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // backgroundColor: '#465A6D'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
    // backgroundColor: '#465A6D'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    width: '10em',
    maxWidth: 300
  },
  active: {
    backgroundColor: '#97D2D3',
    color: '#4B5F75',
    '& $icon': {
      color: '#4B5F75'
    },
    '&:hover': {
      background: "#97D2D3",
    }
  },
  secondaryActive: {
    backgroundColor: '#97D2D3',
    color: '#4B5F75',
    '& $icon': {
      color: '#4B5F75'
    },
    '&:hover': {
      background: "#97D2D3",
    }
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, isDesktop, ...rest } = props;

  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {

    if(isDesktop) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }

  }, [isDesktop])

  // const pages = [
  //   {
  //     title: 'Locations',
  //     href: '/locations',
  //     icon:  <FontAwesomeIcon icon={faBuilding} size="lg"/>
  //   },
  //   {
  //     title: 'Corporate ID Linking',
  //     href: '/accounts/linking',
  //     icon: <FontAwesomeIcon icon={faLink} size="lg" />
  //   },
  //   {
  //     title: 'Workers',
  //     href: "/workers",
  //     icon: <FontAwesomeIcon icon={faUserFriends} size="lg" />,
  //   },
  //   {
  //       title: 'Quarantined Workers',
  //       href: '/quarantined',
  //       icon: <FontAwesomeIcon icon={faViruses} size="lg" />
  //   },
  //   {
  //     title: 'Health Checkers',
  //     href: '/checkers',
  //     icon: <FontAwesomeIcon icon={faStarOfLife} size="lg" />
  //   },
  //   {
  //     title: 'Risk Management',
  //     href: "/healthRisk",
  //     icon: <FontAwesomeIcon icon={faHeartbeat} size="lg"/>,
  //   },
  //   // {
  //   //   title: 'Corporate Services',
  //   //   href: '/services',
  //   //   icon: <FontAwesomeIcon icon={faPercent} size="lg" />,
  //   //   subPages: [
  //   //     {
  //   //       title: 'zennya Services',
  //   //       href: '/services/corporate'
  //   //     },
  //   //     {
  //   //       title: 'Availed Services',
  //   //       href: '/services/availed'
  //   //     }
  //   //   ]
  //   // }
  // ];

  let pages = [
    {
      title: 'Reports',
      href: '/reports',
      icon: <DashboardIcon className={classes.defaultIconSize} />
    },
    {
      title: 'Locations',
      href: '/locations',
      icon:  <FontAwesomeIcon icon={faBuilding} size="lg"/>
    },
    {
      title: 'People',
      href: "/workers",
      icon: <FontAwesomeIcon icon={faUserFriends} size="lg" />,
      subPages: [
        {
          title: 'Worker List',
          href: '/workers'
        },
        {
          title: 'Dependents List',
          href: '/people/dependents'
        },
        {
          title: 'Third Party List',
          href: '/people/thirdparty'
        },
        {
          title: 'Account Linking',
          href: '/accounts/linking'
        },
        {
          title: 'Health Checkers',
          href: '/checkers'
        }
      ]
    },
    {
      title: 'Risk Management',
      href: "/quarantined",
      icon: <FontAwesomeIcon icon={faHeartbeat} size="lg"/>,
      subPages: [
        {
          title: 'Quarantined Workers',
          href: '/quarantined'
        },
        {
          title: 'Health Risk',
          href: '/healthRisk/workers'
        },
        // {
        //   title: 'Health Risk Dependents',
        //   href: '/healthRisk/dependents'
        // },
        // {
        //   title: 'Health Risk Thirdparty',
        //   href: '/healthRisk/thirdParty'
        // },
        // {
        //   title: 'old health risk',
        //   href: '/oldHealthRisk'
        // }

      ]
    },
  ];

  const lockedItems = [{
    title: 'Events',
    href: '/calendar',
    icon: <FontAwesomeIcon icon={faCalendar} size="lg" />,
    subPages: [
      {
        title: 'Calendar',
        href: '/calendar'
      },
      // {
      //   title: 'List Events',
      //   href: '/events'
      // },
      {
        title: 'Cohorts',
        href: '/cohorts'
      }
    ]
  }]

  const allowedCompanies = process.env.REACT_APP_EVENTS_COMPANY_UNLOCKED?.split(',')??[]
  console.log("📢[Sidebar.js:298]:", allowedCompanies);
  console.log("📢[Sidebar.js:299]:", process.env.NODE_ENV);
  if (process.env.NODE_ENV === "production") {
    if (allowedCompanies.includes(props?.user?.settings?.corporate_id?.toString())) {
      pages = [...pages, ...lockedItems]
    }
  } else {
    pages = [...pages, ...lockedItems]
  }

  const pages2 = [
    // {
    //   title: 'User Management',
    //   href: '/settings/usermanagement',
    //   icon: <FontAwesomeIcon icon={faUsersCog} className={classes.defaultIconSize} size="lg" />
    // },
    {
      title: 'Account Settings',
      href: "/settings/company",
      icon: <SettingsIcon className={classes.defaultIconSize} size="lg" />,
    },
    // {
    //   title: 'Work Readiness Logic',
    //   href: '/work/logic',
    //   icon: <DeviceHubIcon />
    // },
    // {
    //   title: 'Packages',
    //   href: '/packages',
    //   icon: <LoyaltyIcon />
    // },
    // {
    //   title: 'Permissions',
    //   href: '/permissions',
    //   icon: <SettingsIcon />
    // }
  ];

  return (
    <div className={ classes.root }>
        <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: openDrawer,
            })}
          >
            <MenuIcon />
          </IconButton>
          <TopBar />
          {/* <Typography variant="h6" noWrap>
            Mini variant drawer
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <RouterLink className={classes.textLeft} to="/">
            { openDrawer && <img
              className={classes.logo}
              alt="Logo"
              src={zennya_logo}
            /> }
          </RouterLink>
          <IconButton onClick={handleDrawerClose} style={{ color: '#fff' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <Grid container spacing={0} style={{ border: '1px solid white'}}>
          <Profile openDrawer={openDrawer} />
        </Grid>
        <Grid container spacing={0} style={{ backgroundColor: '#465A6D',
          height: '100vh'}}>
          <SidebarNav
            openDrawer={openDrawer}
            className={classes.nav}
            pages={pages}
            pages2={pages2}
          />
        </Grid>
            {/* <div
              {...rest}
              className={clsx(classes.root)}
            >
              <Profile />
          <Divider />
          <SidebarNav
            className={classes.nav}
            pages={pages}
            pages2={pages2}
          />
          </div> */}
      </Drawer>
    </div>
        // <Drawer
    //   anchor="left"
    //   classes={{ paper: classes.drawer }}
    //   onClose={onClose}
    //   open={open}
    //   variant={variant}
    // >
    //   <div
    //     {...rest}
    //     className={clsx(classes.root, className)}
    //   >
    //     <Profile />
    //     <SidebarNav
    //       className={classes.nav}
    //       pages={pages}
    //       pages2={pages2}
    //     />
    //   </div>
    // </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const WrappedSidebar = (props) => {
  return (
    <UserProvider>
      <UserContext.Consumer>
        {(value) => <Sidebar {...props} {...value} />}
      </UserContext.Consumer>
    </UserProvider>
  );
};
export default WrappedSidebar;
