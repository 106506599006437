import React, { useMemo, useState } from "react";
import {
    Button
} from "@material-ui/core";
import SaveEmployeeDialog from "./SaveEmployeeDialog";
import CorporateEmployeeService from "../services/corporate-employee.service"
import { PEOPLE_TYPE } from "./employeeList";
import logger, { snackbar } from "../services/logger.service";

export default function ({peopleType, ...props}) {

    const { partnerId, addEmployeeCallback } = props
    const [ modal, setModal ] = useState(false)
    const [ isLoading, setLoader ] = useState(false)
    const { enqueueSnackbar } = snackbar()
    const pType = useMemo(() => {
        switch (peopleType) {
            case PEOPLE_TYPE.WORKERS:  return 'worker'
            case PEOPLE_TYPE.DEPENDENTS: return 'dependent'
            case PEOPLE_TYPE.THIRDPARTY: return 'external worker'
            default:
                return 'worker'
        }
    }, [peopleType]);
    function addEmployee(employee) {
        const params = {
            ...employee,
            company_id: employee.company_id,
            first_name: employee.first_name,
            middle_name: employee.middle_name,
            last_name: employee.last_name,
            email: employee.email,
            gender: employee.gender,
            birth_date: employee.birthdate,
            mobile_number: employee.mobile_number,
            role: employee.role,
            worker_type: employee.worker_type,
            seniority: employee.seniority,
            department: employee.department,
            external_employer: employee.external_employer,
            business_unit: employee.business_unit,
            strategic_business_unit: employee.strategic_business_unit,
            entity: employee.entity
        }

        setLoader(true)

        CorporateEmployeeService.addEmployee(partnerId, params)
            .then(() => {
                addEmployeeCallback()
                setModal(false)
            })
            .catch((error) => {
                logger.err(enqueueSnackbar, <div>Error ${error?.response?.status} ${error?.response?.data?.message?.en}<br/> ${error?.response?.data?.details}</div>)
            })
            .finally(() => {
                setLoader(false)
            })

    }

    return (
        <React.Fragment>
            <Button
                onClick={() => setModal(true)}
                color="primary"
                variant="outlined"
            >
                Add {pType}
            </Button>
            <SaveEmployeeDialog
                partnerId={partnerId}
                modal={modal}
                setModal={setModal}
                title={`Add ${pType}`}
                subtitle={`Add a new ${pType}`}
                actionText={"Add"}
                action={addEmployee}
                isActionLoading={isLoading}
            />
        </React.Fragment>
    )
}
