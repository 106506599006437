import React, { useCallback, useContext, useEffect} from "react";
import {
    AppBar, Box, Chip,
    Grid,
    IconButton, Button, Paper,
    Typography,
    Link,
    Avatar, Tabs, Tab,
    LinearProgress,
    Menu,
    Divider,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody
} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles";
import BackButton from "../components/backButton";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import corporateHealthService from "../services/corporate-health.service";
import moment from "moment"
import Qrcode from "qrcode.react";
import TableInfiniteScroll from "../components/tableInfiniteScroll";
import MoreEmployeeProfileMenu from "./MoreEmployeeProfileMenu";
import corporateEmployeeService from './../services/corporate-employee.service';
import UserList from './userList';
import { Alert } from '@material-ui/lab';
import v from "voca";
import {Link as RouterLink} from 'react-router-dom'
import { useState } from "../utils/stateref";
import DataGridProvider, {
	actions,
	DataGridContext
} from '@zennya/web-component-library/src/components/DataGrid/DataGridContext';
import { DataGrid } from "@zennya/web-component-library/src/components/DataGrid";
import { DataGrid2 } from "@zennya/web-component-library/src/components/DataGrid2/DataGrid2";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        maxWidth: '1440px'
    },
    rootTabs: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    textLeft: {
        textAlign: 'left'
    },
    fontWeightBold: {
        fontWeight: 'bold'
    },
    textCapitalize: {
        textTransform: 'capitalize'
    },
    textEllipsis:  {
        textOverflow: 'ellipsis'
    },
    profileAvatar: {
        [theme.breakpoints.only('xs')]: {
            justifyContent: "center"
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end"
        }
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        margin: theme.spacing(1)
    },
    outlinedBtn: {
        border: '2px solid rgba(146, 179, 213, 0.5)'
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ minHeight: '16em'}}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function (props) {
    const classes = useStyles();
    const history = useHistory();

    const reset = props.reset
    var today = moment().format('YYYY-MM-DD');
    const [ assessmentRecorded, setAssessmentRecorded ] = useState('');

    let employeeId = props.match.params.id
    const initialPagination = {
        page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
        page_offset: 0
    }
    const [ employee, setEmployee ] = useState({})
    const [editHash, setEditHash] = useState(Math.random());
    const [ covidResults, setCovidResults ] = useState([]);
    const [ isLoadingDetails, setDetailsLoader ] = useState(false)
    
    const [ pagination, setPagination ] = useState(initialPagination)
    
    const [ restrictions, setRestrictions ] = useState([]);

    const [healthPass, setHealthPass] = useState();
    
    const [ value, setValue ] = useState(0);
    const [ value2, setValue2 ] = useState(0);
    const handleChange = (event, val) => {
        setValue(val);
    }

    const handleChange2 = (event, val2) => {
        setValue2(val2);
    }

    const resultColumns = [
        {
            id: 'test',
            label: 'Test',
            minWidth: 85,
            align: 'left',
        },
        {
            id: 'dateAdded',
            label: 'Date Added',
            minWidth: 20,
            align: 'left',
        },
        {
            id: 'dateValidity',
            label: 'Date Validity',
            minWidth: 85,
            align: 'left',
        },
        {
            id: 'label',
            label: 'Status',
            minWidth: 85,
            align: 'left',
        },
    ];

    function handleLogs() {
        setPagination({
            ...pagination,
            page_offset: initialPagination.page_size + pagination.page_offset
        })
    }

    function resetThenPush(path) {
        setEmployee({})
        setPagination(initialPagination)
        // setLogs(initialLogs)
        history.push(path)
    }

    const getIdentityType = useCallback(
        () => {
            if (employee?.external_employer) return "External"
            else if (employee?.company_id) return "Employee"
            else return "Dependent"
        },
        [employee],
    );

    const fetchEmployeeData = useCallback(
        async () => {
            setDetailsLoader(true)
            try {
                let data = await corporateHealthService.getEmployeeDetails(employeeId)
                data  = {...data,
                    vaccines: data?.vaccines?.sort((a, b) =>{
                        return new Date(a.doseDate) - new Date(b.doseDate)
                    })
                }
                setEmployee(data);
                setEditHash(Math.random())
                const healthPassResult = await corporateHealthService.getClientHealthPass(data?.employee?.id)
                setHealthPass(healthPassResult)
                getEmployeeAccessRestrictions(employeeId);
                getWorkerCOVIDResults(employeeId);

                if(data.covid_assessment && data.covid_assessment.date) {
                    setAssessmentRecorded(moment(data.covid_assessment.date).format('YYYY-MM-DD'));
                }    
            } catch (error) {
                console.log("📢[employeeProfile.js:194]:", error);
                
            } finally {
                setDetailsLoader(false)
            }
        },
        [employeeId],
    );
    
    const setEmployeeUpdate = useCallback(
        async () => {
            await fetchEmployeeData()
        },
        [fetchEmployeeData],
    );
    useEffect(() => {
        fetchEmployeeData()
    }, [employeeId])

    function getEmployeeAccessRestrictions(employeeId) {
        Promise.all(
            [
                corporateEmployeeService.getEmployeeLocationRestrictions(employeeId),
                corporateEmployeeService.getEmployeeLocationTypeRestrictions(employeeId)
            ]
        ).then(responses => {
            setRestrictions((responses[1].list).concat(responses[0].list));
        })
    }

    const getValidityDate = (date) => {
        return `${moment(date[0].date_created).add(14, 'days').format('MMMM D, YYYY')}`;
    }

    const getWorkerCOVIDResults = (id) => {
        corporateEmployeeService.getWorkerCOVIDResults(id).then(
            response => {
                const newList = response.list.map((result, index) => {
                    return {
                        dateAdded: moment(result.date_added).format('MMMM DD, YYYY hh:mm a'),
                        label: result.status_string,
                        test: result.test_info && result.test_info.label ? result.test_info.label : '',
                        dateValidity: !result.is_expired ? moment(result.date_added).add(14, 'days').format('MMMM D, YYYY hh:mm a') : 'Expired',
                    }
                });

                setCovidResults({
                    list: newList,
                    count: response.count
                });
            }
        ).catch(error => {

        })
    }

    const sourceLabel = (source) => {
        switch (source) {
            case 'USER':
                return "External"
            case 'ACTUAL':
                return "Zennya"
            default:
                return "Unknown"
        }
    }
    return (
        <Grid container className={classes.root} justify={"flex-start"} alignItems={"center"} spacing={3}>
            <Grid item>
                <BackButton />
            </Grid>
            { isLoadingDetails && <LinearProgress style={{width: '100%'}}/>}
            <Grid container spacing={3} style={{ margin: 10}}>
                <Grid item container spacing={2} xs={12} sm={6}>
                    <Grid item md={3} xs={12}>
                        <Avatar alt={employee.display_name} src={employee.profile_pic_url} className={classes.large} />
                    </Grid>
                    <Grid item md={9} xs={12} align="left">
                        <Typography variant="h4" style={{ fontWeight: 700, color: '#5A5A5A'}}>
                            { employee.last_name }, { employee.first_name}
                        </Typography>
                        {employee.quarantine && employee.quarantine.status.status === 'ONGOING' ?
                             <Chip size="small" style={{ backgroundColor: '#FFCFCB', color: '#F38181', border: '1px solid #FFCFCB', borderRadius: 5}} label={
                                <Typography variant="caption" style={{ fontWeight: 700}}>
                                    { employee.quarantine.category.label }
                             </Typography>} />
                            :
                            (employee.gate_traffic && employee.gate_traffic.risk_level && 
                                employee.temperature && employee.temperature.reading &&
                                employee.gate_traffic.risk_level.label === 'LOW Risk' ? 
                                <Chip size="small" style={{ backgroundColor: '#C2E7E0', color: '#288985', border: '1px solid #C2E7E0', borderRadius: 5}} label={
                                    <Typography variant="caption" style={{ fontWeight: 700}}>
                                        FIT TO WORK
                                    </Typography>} /> : '-' )
                        }
                        <Typography variant="body1">
                            <b style={{ color: '#5a5a5a'}}>
                                ID: { employee.company_id || '-'}
                            </b>
                        </Typography>
                        <Typography variant="body1">
                            { employee.role || '-' }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={0} xs={12} sm={4} style={{ backgroundColor: '#F7F7F7'}}>
                    <Grid item xs={12} align="left">
                        <Typography variant="caption">
                            Enter:&nbsp;
                            { employee.gate_traffic ?
                                (
                                    employee.gate_traffic.timestamp_entrance ?
                                    moment(employee.gate_traffic.timestamp_entrance).format('MM/DD/YYYY hh:mm A') :
                                    '-'
                                )
                                : '-'
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Typography variant="caption">
                            Exit:&nbsp;
                            { employee.gate_traffic ?
                                (
                                    employee.gate_traffic.timestamp_exit ?
                                    moment(employee.gate_traffic.timestamp_exit).format('MM/DD/YYYY hh:mm A') :
                                    '-'
                                )
                                : '-'
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Typography variant="caption">
                            Temperature:&nbsp;
                            { employee.temperature && employee.temperature.reading ?
                                (
                                    employee.temperature.reading ?
                                    `${employee.temperature.reading}° Celsius` :
                                    '-'
                                )
                                : '-'
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Typography variant="caption">
                            Daily COVID-19 Assessment:&nbsp;
                            {
                                employee.covid_assessment && employee.covid_assessment.date && today === assessmentRecorded?
                                (
                                    employee.covid_assessment.passed ? 'Passed' : 'Not Cleared'
                                )    :
                                '-'
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"flex-start"} justify={"flex-end"} xs={12} sm={2} style={{ paddingTop: 0}}>
                    <MoreEmployeeProfileMenu
                        restrictions={restrictions}
                        company={props.company}
                        disabled={isLoadingDetails}
                        employeeId={employeeId}
                        employee={employee}
                        editHash={editHash}
                        updateEmployeeCallback={setEmployeeUpdate}
                    >{editHash}</MoreEmployeeProfileMenu>
                </Grid>
            </Grid>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.rootTabs}>
                        <AppBar position="static" elevation={0}>
                            <Tabs value={value} onChange={handleChange} elevation={0} aria-label="simple tabs example" style={{ color: '#5A5A5A', backgroundColor: '#F7F7F7'}}>
                                <Tab label="Worker Details" {...a11yProps(0)} />
                                <Tab label="Contact Details" {...a11yProps(1)} />
                                <Tab label="Medical Information" {...a11yProps(2)}/>
                                <Tab label="QR Code" {...a11yProps(3)}/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <Grid container spacing={1} alignItems="flex-start" justify="flex-start">
                                <Grid item xs={3} align="left">
                                    <b>
                                        Role
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.role || '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Work Nature
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.work_nature || '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Seniority
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.seniority || '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Department
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.department || '-' }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Entity
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.entity || '-' }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Strategic Business Unit
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.strategic_business_unit || '-' }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Business Unit
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.business_unit || '-' }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Company ID
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { `${employee?.affiliation?.label} - ${employee?.company_id}` || '-' }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Identity Type
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        {v.capitalize(employee?.type??'worker', true)}
                                    </Typography>
                                </Grid>
                                {employee?.type &&
                                <>
                                    <Grid item xs={3} align="left">
                                        <b>
                                            {employee?.type === 'EXTERNAL' ? 'External Employer' : 'Dependent of'}
                                        </b>
                                    </Grid>
                                    <Grid item xs={9} align="left">
                                        {employee?.type === 'EXTERNAL' ?
                                        <Typography variant="body1">
                                            { employee.external_employer || '-' }
                                        </Typography> :
                                        <Typography variant="body1">
                                            {employee?.primary_employee_id}
                                        </Typography>
                                        }
                                    </Grid>
                                </>
                                }
                                {employee?.type === 'DEPENDENT' &&
                                        <>
                                        <Grid item xs={3} align="left">
                                            <b>
                                                Relationship
                                            </b>
                                        </Grid>
                                        <Grid item xs={9} align="left">
                                            <Typography variant="body1">
                                                {v.capitalize(employee?.dependent_type, true)}
                                            </Typography>
                                        </Grid>
                                        </>
                                }
                                {/* <Grid item xs={3} align="left">
                                    <b>
                                        Restricted on
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    {
                                        restrictions.length >= 1 ?
                                        restrictions.map((r, index) => {
                                            return ( r ?  <Typography key={index} variant="body1" component="span">
                                                { r.location ? `${r.location.label}` : undefined}{ index !== restrictions.length - 1 ? ' , ' : ''}
                                            </Typography> : undefined)
                                        })
                                    : undefined }
                                </Grid> */}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Grid container spacing={1} alignItems="flex-start" justify="flex-start">
                                <Grid item xs={3} align="left">
                                    <b>
                                        Email
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.email ? employee.email : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Mobile Number
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.mobile_number ? employee.mobile_number : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Address
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        {(employee?.residence?.building_info||employee?.residence?.street) && `${employee?.residence?.building_info} ${employee?.residence?.street}, `}{`${[ employee?.residence?.barangay?.name, employee?.residence?.city?.name, employee?.residence?.province?.name, employee?.residence?.region?.name].filter(a=>!!a).join(', ')}` }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Grid container spacing={1} alignItems="flex-start" justify="flex-start">
                                <Grid item xs={3} align="left">
                                    <b>
                                        Gender
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.gender || '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Birthdate
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        { employee.birthdate ? moment(employee.birthdate).format('MM/DD/YYYY') : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Age
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                     { employee.birthdate ? moment().diff(employee.birthdate, 'years', false) : '-' }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Civil Status
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                     { employee?.civil_status?.type || '-' }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Health Conditions
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body2"><b>Allergies</b></Typography>
                                    <ul className={classes.chipRoot}>
                                        {(employee?.allergies??[]).map((a, k) => (
                                            <li key={`all${k}`}>
                                                <Chip label={a.label} className={classes.chip}/>
                                            </li>
                                        )
                                        )}
                                    </ul>
                                    <Typography variant="body2"><b>Comorbidities</b></Typography>
                                    <ul className={classes.chipRoot}>
                                        {(employee?.comorbidities??[]).map((a, k) => (
                                            <li key={`all${k}`}>
                                                <Chip label={a.label} className={classes.chip}/>
                                            </li>
                                        )
                                        )}
                                    </ul>
                                </Grid>
                                {/* <Grid item xs={3} align="left">
                                    <b>
                                        COVID Antibody Test
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                        { employee.covid_test_result && employee.covid_test_result.status ? 
                                            <React.Fragment>
                                                {
                                                    employee.covid_test_result && employee.covid_test_result.status === 'TESTED' ?
                                                        <Chip size="small" style={{ backgroundColor: '#C4EDC6', color: '#54A557', border: '1px solid #C4EDC6', borderRadius: 5}} label={
                                                            <Typography variant="caption" style={{ fontWeight: 700}}>
                                                                Tested
                                                            </Typography>
                                                        } />
                                                        : employee.covid_test_result.status === 'FOR RE-TEST' ?
                                                        <Chip size="small" label={
                                                            <Typography variant="caption" style={{ fontWeight: 700}}>
                                                                For re-test
                                                            </Typography>
                                                        } style={{ backgroundColor: '#FE974C', color: '#fff', border: '1px solid #FE974C', borderRadius: 5}}/>
                                                        :  <Chip size="small" label={
                                                            <Typography variant="caption" style={{ fontWeight: 700}}>
                                                                Not Tested
                                                            </Typography>
                                                        } style={{ backgroundColor: '#E8685E', color: '#fff', border: '1px solid #E8685E', borderRadius: 5}}/>
                                                }
                                            </React.Fragment>
                                        : undefined }
                                </Grid> */}
                                <Grid item xs={3} align="left">
                                    <b>
                                        COVID Vaccination
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <TableContainer>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Vaccine</TableCell>
                                                    <TableCell>Dose Date</TableCell>
                                                    <TableCell>CBCR ID</TableCell>
                                                    <TableCell>Lot Number</TableCell>
                                                    <TableCell>Batch Number</TableCell>
                                                    <TableCell>Location</TableCell>
                                                    <TableCell>Source</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(employee?.vaccines??[]).map((item, idx) => (
                                                    <TableRow key={`vaccine${idx}`}>
                                                        <TableCell>{item?.vaccine}</TableCell>
                                                        <TableCell>{moment(item?.doseDate).format('LL')||'-'}</TableCell>
                                                        <TableCell>{item?.cbcrId}</TableCell>
                                                        <TableCell>{item?.lotNumber}</TableCell>
                                                        <TableCell>{item?.batchNumber}</TableCell>
                                                        <TableCell>{item?.sourceLocation}</TableCell>
                                                        <TableCell>{sourceLabel(item?.source)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    
                                </Grid>
                                <Divider/>
                                <Grid item xs={3} align="left">
                                    <b>
                                        Flu Vaccination
                                    </b>
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="body1">
                                        <b>Vaccine</b> : {employee?.flu_vaccine?.brand}
                                    </Typography>
                                   <Typography variant="body1">
                                        <b>Date</b> : {moment(employee?.flu_vaccine?.dose_date).format('LL')|| '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Grid container spacing={1} alignItems="flex-start" justify="flex-start">
                            {!!healthPass?
                                <Qrcode
                                    value={healthPass?.health_pass_hash}
                                    renderAs="svg"
                                    size={256}
                                />
                                :
                                <Alert severity="warning">QRCode not available</Alert>
                            }
                            </Grid>
                        </TabPanel>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.rootTabs}>
                        <AppBar position="static" elevation={0}>
                            <Tabs value={value2} onChange={handleChange2} elevation={0} aria-label="simple tabs example" style={{ color: '#5A5A5A', backgroundColor: '#F7F7F7'}}>
                                <Tab label="Timeline" {...a11yProps(3)} />
                                <Tab label="Medical Records" {...a11yProps(4)}/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value2} index={0}>
                            
                                <DataGridProvider>
                                    <LoggingTable pagination={pagination} company={props.company} employeeId={employeeId}/>
                                </DataGridProvider>
                            
                        </TabPanel>
                        <TabPanel value={value2} index={1}>
                            <UserList
                                columns={resultColumns || []}
                                rows={covidResults.list || []}
                                count={covidResults.count || 0}
                                rowsPerPage={100}
                                loader={null}
                                handlePage={null}
                                pagination={false}
                            />
                        </TabPanel>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}


// (isLoadingLogs, logs, tableColumns, handleLogs, isLoadingDetails) {
const LoggingTable = ({employeeId, company, pagination}) => {
    const [state, dispatch] = useContext(DataGridContext);
    const [pageState, setPage, page] = useState(1);
	const [loadRows, setLoadRows, loadRowsRef] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [params, setParams, paramsRef] = useState({
		page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
		page_offset: 0
	});
    const [reset, setReset, resetRef] = useState();

    const history = useHistory();

    const fetchLogs = useCallback(
        async () => {
            try {
                dispatch({ type: actions.SET_LOADING })
                const data = await corporateHealthService.getEmployeeLogs(company, paramsRef.current)
                let newList = data.list.map(log => {
                    return {
                        action: (
                            <Typography style={{ textTransform: 'capitalize'}}>
                                { log.sub_type_label || log.type.replace(/_/g, " ") }
                            </Typography>
                        ),
                        description: log.description,
                        performed_by: ( Number(log.performed_by.employee.employee_id) !== Number(employeeId)
                                ? (
                                    <Link
                                        onClick={() => {
                                            history.push(`/workers/${log.performed_by.employee.employee_id}`)
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        { log.performed_by.employee.last_name }, { log.performed_by.employee.first_name }
                                    </Link>
                                )
                                : `${log.performed_by.employee.last_name}, ${log.performed_by.employee.first_name}`
                        ),
                        date_created: moment(log.date_created).format("hh:mm:ss A - MMM D, Y")
                    }
                })
                let appendRows = [...newList]
				if (!resetRef.current){
					appendRows = [...loadRowsRef.current, ...newList]
				} else {
                    appendRows = [...newList]
                }
                setLoadRows(appendRows)
				dispatch({
					type: actions.LOAD_ROWS,
					payload: {
						rows: appendRows,
					},
				});

            } catch (e) {
                
            } finally {
                dispatch({ type: actions.SET_DONE_LOADING })
                setLogsLoader(false)
                setTimeout(() => {
                    setReset(true)
                }, 1000 * 10)
            }
        },
        [company, dispatch, employeeId, history, loadRowsRef, paramsRef, resetRef, setLoadRows, setReset],
    );

    const loadMorePages = useCallback(
		() => {
			const newParams = reset||resetRef.current?
            {
                ...paramsRef.current,
                page_offset: 0,
                employee_id: employeeId
            }:{
				...paramsRef.current,
				page_offset: ((page.current) * params.page_size) - params.page_size,
                employee_id: employeeId
			}
			setParams(newParams)
			fetchLogs()
			setPage(page.current + 1)
            
		},
		[employeeId, fetchLogs, page, params.page_size, paramsRef, reset, resetRef, setPage, setParams, setReset],
	);

    useEffect(() => {
        loadMorePages()
    }, [loadMorePages]);

    const initialLogs = {
        list: [],
        count: 0
    }
    const [ logs, setLogs ] = useState(initialLogs)

    useEffect(() => {
		dispatch({
			type: actions.LOAD_COLUMNS,
			payload: {
				columns: [
                    { key: "action", colId: "action", name: "ACTION", align: 'left' },
                    { key: "description", colId: "description", name: "DESCRIPTION", align: 'left', width: '60%' },
                    { key: "performed_by", colId: "performed_by", name: "ADMIN", align: 'left' },
                    { key: "date_created", colId: "date_created", name: "DATE", align: 'left' }
                ]
			},
		});
	}, [dispatch]);

    const [ isLoadingLogs , setLogsLoader ] = useState(false)
    
    return (
        
            <div style={{ display: "flex", flexDirection: "column" }}>
            {isLoadingLogs && <LinearProgress style={{ width: '100%' }} />}
            <DataGrid
            // style={{ height: '100%' }}
            // containerStyle={{
            //     flex: '1 1 0',
            // }}
				totalCount={totalCount}
				onLoadMore={() => {
                    setReset(false)
					loadMorePages({reset:false})
				}}
				showSelector
				gridProps={{
					rowKeyGetter: row => {
						return row.id;
					},
					emptyRowsRenderer: () => (
						<Box textAlign="center" p="1rem" color="grey">
							No matching records found
						</Box>
					),
					rowHeight: 44,
				}}
				leftAccessory={() => <Typography variant="h5">Logs</Typography>}
			/>
            </div>
        
    )
}

