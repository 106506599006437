import { makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import Employees, { Dependents, PEOPLE_TYPE, ThirdParty } from '../employeeList';

const useStyles = makeStyles((theme) => {
  return {}
})
const WorkerList = ({ company, peopleType=PEOPLE_TYPE.WORKERS, risk, riskBar }) => {
  const classes = useStyles()
  let Component = Employees
  switch (peopleType) {
    case PEOPLE_TYPE.WORKERS:
      Component=Employees
      break;
    case PEOPLE_TYPE.DEPENDENTS:
      Component=Dependents
      break
    case PEOPLE_TYPE.THIRDPARTY:
      Component=ThirdParty
      break;
    default:
      break;
  }
  return <Component
    company={company}
    hasMetrics={false}
    isHealth
    hasImportBar={false}
    compact
    hasRisk
    riskFilter={risk}
    hasPeopleTypeFilter
    riskBar={riskBar}
  />
}

export { WorkerList }