import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom';
import Main from './shared/Main';
import Dashboard from './dashboard/dashboard';
import CorporateHealthPass from './extHealthPass/extHealthPass';
import Login from './auth/login';
import AuthenticatedRoute from './auth/authenticatedRoute';
import Offices from './offices/officeList';
import Employees, { Dependents, PEOPLE_TYPE, ThirdParty } from './employees/employeeList';
import HealthCheckers from './employees/healthCheckerList';
import OfficeView from './offices/officeView';
import QuarantinedEmployees from './employees/quarantinedEmployees';
import AccountLinking from './accountLinking/accountLinking';
import EmployeeProfile from './employees/employeeProfile';
import HealthRisk from './employees/healthRisk';
import LoginLanding from './auth/loginLanding';
import { CompanyProvider } from './auth/CompanyDetailsContext';
import { UserProvider } from './context/UserContext';
import CompanyProfile from './settings/companyProfile/CompanyProfile'
import GenericError from './errors/genericError';
import ForgotPassword from "./auth/forgotPassword";
import ResetPassword from "./auth/resetPassword";
import CorpPackages from './corpPackages/corpPackages';
import CorpPackageDetails from './corpPackages/corpPackageDetails';
import AvailedPackages from './corpPackages/availedPackages';
import { SnackbarProvider } from 'notistack';
import Events from './events/events';
import EventCalendar from './events/calendar';
import Cohorts from './events/cohorts';
import CohortDetails from './events/cohortDetails';
import CreateCohorts from './events/createCohorts';
import ReportList from './reports/reportList';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
  IconButton
} from "@material-ui/core";
import {
  Cancel as CancelIcon
} from "@material-ui/icons";
import ViewCohort from './cohort/ViewCohort';
import { ConfirmProvider } from 'material-ui-confirm';
import EventDetail from './events/eventDetail';
import { QueryParamProvider } from 'use-query-params';
import { UserAdminDashboard } from './settings/usermanagement/dashboard';
import { CohortDetailPage } from './events/cohortDetailPage';
import { Versioning } from './utils/versioning';
import { HealthRiskView } from './employees/healthRiskView';

const Routes = (props) => {
  const history = createBrowserHistory();

  const snackbarRef = React.createRef()

  return (
      <Router history={history}>
        <QueryParamProvider 
          ReactRouterRoute={Route}
          stringifyOptions={{
              encode: false,
          }}
        >
          <SnackbarProvider
            ref={snackbarRef}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            maxSnack={3}
            hideIconVariant
            action={(key) => (
              <IconButton
                onClick={() => snackbarRef.current.closeSnackbar(key)}
                style={{
                  color: "white"
                }}
              >
                <CancelIcon />
              </IconButton>
            )}
          >
            <Switch>
              <Route path='/healthPass/:hashId' exact component={CorporateHealthPass} />
              <AuthenticatedRoute path='/' exact component={Offices} />
              <AuthenticatedRoute path='/reports' exact component={ReportList} />
              <AuthenticatedRoute path='/locations' exact component={Offices} />
              <AuthenticatedRoute path='/locations/:locationId' exact component={OfficeView} />
              <AuthenticatedRoute path='/accounts/linking' exact component={AccountLinking} />
              <AuthenticatedRoute path='/workers' exact component={Employees}  />
              <AuthenticatedRoute path='/people/dependents' exact component={Dependents}  />
              <AuthenticatedRoute path='/people/thirdparty' exact component={ThirdParty}  />
              <AuthenticatedRoute path='/quarantined' exact component={QuarantinedEmployees} />
              <AuthenticatedRoute path='/healthRisk/WORKERS' exact component={HealthRiskView} peopleType={PEOPLE_TYPE.WORKERS}/>
              <AuthenticatedRoute path='/healthRisk/dependents' exact component={HealthRiskView} peopleType={PEOPLE_TYPE.DEPENDENTS}/>
              <AuthenticatedRoute path='/healthRisk/thirdParty' exact component={HealthRiskView} peopleType={PEOPLE_TYPE.THIRDPARTY}/>
              <AuthenticatedRoute path='/oldHealthRisk' exact component={HealthRisk} />
              <AuthenticatedRoute path='/workers/:id' exact component={EmployeeProfile} />
              <AuthenticatedRoute path='/checkers/:id' exact component={EmployeeProfile} />
              <AuthenticatedRoute path='/checkers' exact component={HealthCheckers} />
              <AuthenticatedRoute path='/services/corporate' exact component={CorpPackages} />
              <AuthenticatedRoute path='/services/packages/:id' exact component={CorpPackageDetails} />
              <AuthenticatedRoute path='/services/availed' exact component={AvailedPackages} />
              <AuthenticatedRoute path='/settings/company' exact component={CompanyProfile} />
              <AuthenticatedRoute path='/settings/usermanagement' exact component={UserAdminDashboard} />
              <AuthenticatedRoute path='/events' exact component={Events} />
              <AuthenticatedRoute path='/events/:eventId' exact component={EventDetail} />
              <AuthenticatedRoute path='/calendar' exact component={EventCalendar} />
              <AuthenticatedRoute path='/cohorts' exact component={Cohorts} />
              <AuthenticatedRoute path='/cohort' exact component={ViewCohort} />
              <AuthenticatedRoute path='/cohorts/:cohortId' exact component={CohortDetailPage} />
              <Route path='/login' exact component={LoginLanding} />
              <Route path='/forgot-password' exact component={ForgotPassword} />
              <Route path='/reset-password' exact component={ResetPassword} />
              <AuthenticatedRoute path='*' component={GenericError} />
            </Switch>
          </SnackbarProvider>
        </QueryParamProvider>
      </Router>
    
  )
}


const App = (props) => {
  return (
    <DndProvider backend={HTML5Backend} key={1}>
    <CompanyProvider displayName="Company Context">
      <ConfirmProvider>
      <div className="App">
        <Versioning />
        <Routes />
      </div>
      </ConfirmProvider>
    </CompanyProvider>
    </DndProvider>
  )
}

export default App;
