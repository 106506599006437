import {
    Avatar,
    debounce,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton, Typography
} from "@material-ui/core";
import {
    makeStyles, withStyles
} from "@material-ui/core/styles";
import {
    CameraAlt as CameraAltIcon, Close as CloseIcon, MobileFriendly
} from "@material-ui/icons";
import { FormBuilder } from "@zennya/web-component-library/src/components/FormBuilder";
import clsx from "clsx";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { AutocompleteAddress } from "../components/AutocompleteAddress";
import { GetLoggedInUser } from "../context/UserContext";
import locationsService from "../services/locations.service";
import corporateHealthService from "./../services/corporate-health.service";
import { mapRegionNameToCode } from "../services/address/fetchAddressComponents";
import MaskedInput from "react-text-mask";

import v from 'voca'

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
import { PEOPLE_TYPE } from "./employeeList";
import { matchPath, useLocation, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    bold: {
        fontWeight: "bold"
    },
    textDefaultColor: {
        color: "#494949"
    },
    textSuccessColor: {
        color: "#70BE5C"
    },
    textRight: {
        textAlign: "right"
    },
    subtitle1: {
        fontSize: "1rem"
    },
    fullWidth: {
        width: "100%"
    },
    largeAvatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        margin: theme.spacing(1)
    },
}));

export default function SaveEmployeeDialog(props) {
    const classes = useStyles();
    const user = GetLoggedInUser()
    const { partnerId } = props
    const [allergies , setallergies ] = useState([]);
    const [comorbidities , setcomorbidities ] = useState([]);
    const [regions, setRegions] = useState([]);
    const [relationships, setRelationships] = useState([]);
    const [vaccines, setVaccines] = useState([]);
    const [address, setAddress] = useState();

    const [errors, setErrors] = useState();

    const location = useLocation()
    
    const matchWorkers =useRouteMatch({path:'/workers', strict: false, sensitive: false})
    const matchDependents =useRouteMatch({path:'/people/dependents', strict: false, sensitive: false})
    const matchExternal =useRouteMatch({path:'/people/thirdparty', strict: false, sensitive: false})

    const {
        title, subtitle,
        modal, setModal,
        actionText, action,
        isActionLoading,
        employee,
        editHash
    } = props


    const [ newEmployee, setNewEmployee ] = useState({
        company_id: "",
        first_name: "",
        last_name: "",
        email: "",
        gender: "Male",
        worker_type: "",
        comorbidities:[],
        allergies:[]
    })

    const [ isEmployeeSupplied, setIsEmployeeSupplied ] = useState(false)
    const [ areFieldsValid, setAreFieldsValid ] = useState(false)

    const handleEmployeeChange = name => event => {
        setNewEmployee({
            ...newEmployee,
            [name]: event.target.value
        });
    }

    const [ isInternal, setIsInternal ] = useState(true)
    const [ imageFile, setImageFile ] = useState(null)
    const [ imageUrl, setImageUrl ] = useState('')

    const [age, setAge] = useState(newEmployee.age);
    const [formValues, setFormValues] = useState(newEmployee);

    const [autocompleteEmployeeSearch, setAutocompleteEmployeeSearch] = useState("");
    const [autocompleteEmployeeList, setAutocompleteEmployeeList] = useState([]);

    useEffect(() => {
        async function fetchMedicalStatuses() {
            setallergies((await corporateHealthService.getAllergies())?.data?.list)
            setcomorbidities((await corporateHealthService.getComorbidities())?.data?.list)
            setRegions((await locationsService.fetchRegions())?.list)
            setVaccines((await corporateHealthService.getVaccines())?.data?.list)
            const rels = (await corporateHealthService.getRelationOptions())?.data?.list
            setRelationships(rels.map(item=>{
                return {
                    id: item,
                    label: v.capitalize(item, true)
                }
            }))
            // let initType = PEOPLE_TYPE.WORKERS
            // if (matchDependents) {
            //     initType = PEOPLE_TYPE.DEPENDENTS
            // } else if(matchExternal) {
            //     initType = PEOPLE_TYPE.THIRDPARTY
            // }
            // setIsInternal(initType)
        }
        fetchMedicalStatuses()
    }, []);

    useEffect(() => {
        async function getPrimaryAsList() {
            setAutocompleteEmployeeList(await corporateHealthService.searchEmployee(partnerId, newEmployee?.primary_employee_id))
        }
        if(newEmployee?.primary_employee_id) {
            getPrimaryAsList()
        }
    }, [newEmployee?.primary_employee_id, partnerId]);

    useEffect(() => {
        async function fetchEmployeesAutocomplete() {
            setAutocompleteEmployeeList(await corporateHealthService.searchEmployee(partnerId, autocompleteEmployeeSearch))
        }
        if (!isEmpty(autocompleteEmployeeSearch)) {
            fetchEmployeesAutocomplete()
        }
    }, [autocompleteEmployeeSearch, partnerId]);
    
    useEffect(() => {
        if (!isEmployeeSupplied) {
            let initType = PEOPLE_TYPE.WORKERS
            if (matchDependents) {
                initType = PEOPLE_TYPE.DEPENDENTS
            } else if(matchExternal) {
                initType = PEOPLE_TYPE.THIRDPARTY
            }
            setIsInternal(initType)
        }
    }, [isEmployeeSupplied, matchDependents, matchExternal]);

    const formLayout = useMemo(() => {
        let internalSubForm = []
        switch (isInternal) {
            case PEOPLE_TYPE.WORKERS:
                break;
            case PEOPLE_TYPE.THIRDPARTY:
                internalSubForm = ['external_employer']
                break
            case PEOPLE_TYPE.DEPENDENTS:
                internalSubForm = ['primary_employee_company_id', 'relationship']
                break;
            default:
                break;
        }
        return [
            ["avatar", "company_id"],
            ["first_name", "middle_name", "last_name"],
            ["email", "mobile_number"],
            ["gender", 'civil_status'],
            ["birthdate", ],
            ['building_info', 'street'],
            ['autocomplete'],
            ['barangay', 'city', 'province', 'region'],
            ['allergies', 'comorbidities'],
            ['divider'],
            ['doses'],
            ['flu_vaccine', 'dose_date'],
            ["role", "seniority"],
            ["worker_type", "is_internal"],
            ...internalSubForm, 
            [
            "department",
            "strategic_business_unit",
            "business_unit",
            ],
            []
        ];
    }, [isInternal]);

    const formSetup = useMemo(() => {

        const formElements = {
            divider: {
                type: "component",
                component: () => <Divider />,
            },
            autocomplete: {
                type: 'component',
                component: () => (
                    <AutocompleteAddress onSelectAddress={(value)=>{
                        setAddress(value)}
                    }/>
                ),
            },
            company_id: {
                type: "text",
                label: "ID Number",
                initialValues: newEmployee.company_id??"",
                readOnly: isEmployeeSupplied,
                validator: () => {
                    const validator = Yup.string().nullable().label(' ')
                    return isInternal === PEOPLE_TYPE.DEPENDENTS ? validator : validator.required()
                },
            },
            first_name: {
                type: "text",
                label: "First Name",
                initialValues: newEmployee.first_name??"",
                validator: () => Yup.string().nullable().required().label(' '),
            },
            middle_name: {
                type: "text",
                label: "Middle Name",
                initialValues: newEmployee.middle_name??"",
                validator: () => Yup.string().nullable().label(' '),
            },
            last_name: {
                type: "text",
                label: "Last Name",
                initialValues: newEmployee.last_name??"",
                validator: () => Yup.string().nullable().required().label(' '),
            },
            email: {
                type: "email",
                label: "Email",
                initialValues: newEmployee.email??"",
                validator: () => Yup.string().nullable().email().required().label(' '),
            },
            mobile_number: {
                type: "phone",
                label: "Phone Number",
                initialValues: newEmployee.mobile_number??'',
                validator: () => Yup.string().required().nullable().max(15).matches(/^\+(63\d{10}|(?!63)\d{1,3}\d{10})$/, 'invalid phone number format').label(' '),
            },
            gender: {
                type: "radio",
                label: "Gender",
                initialValues: newEmployee.gender??"",
                options: [
                    { id: "Male", label: "Male" },
                    { id: "Female", label: "Female" },
                ],
                settings: {
                    labelField: "label",
                    valueField: "id",
                    inline: true,
                },
                validator: () => Yup.string().nullable().label(' '),
            },
            birthdate: {
                type: "date",
                label: "Birth Date",
                // disableFuture: true,
                // disablePast: false,
                initialValues: newEmployee.birthdate||null,
                validator: () => Yup.date().nullable().label(' '),
                fieldProps: {
                    
                }
            },
            age: {
                type: "number",
                label: "Age",
                initialValues: newEmployee.age??"",
                validator: () => Yup.number().nullable().label(' '),
            },
            role: {
                type: "text",
                label: "Role",
                initialValues: newEmployee.role??"",
                validator: () => Yup.string().nullable().label(' '),
            },
            seniority: {
                type: "text",
                label: "Seniority",
                initialValues: newEmployee.seniority??"",
                validator: () => Yup.string().nullable().label(' '),
            },
            worker_type: {
                type: "radio",
                label: "Work Nature",
                initialValues: newEmployee.work_nature??"",
                options: [
                    { id: "Office", label: "Office" },
                    { id: "Plant", label: "Plant" },
                    { id: "Field", label: "Field" },
                ],
                settings: {
                    labelField: "label",
                    valueField: "id",
                    inline: true,
                },
                validator: () => Yup.string().nullable().label(' '),
            },
            is_internal: {
                type: "radio",
                label: "Person Type",
                initialValues: newEmployee?.type??isInternal,
                options: [
                    { id: PEOPLE_TYPE.WORKERS, label: "Internal" },
                    { id: PEOPLE_TYPE.THIRDPARTY, label: "Third Party" },
                    { id: PEOPLE_TYPE.DEPENDENTS, label: "Dependent" },
                ],
                settings: {
                    labelField: "label",
                    valueField: "id",
                    inline: true,
                },
                validator: () => Yup.string().nullable().required().label(' '),
                onChange: (fieldName, value) => {
                    setIsInternal(value)
                }
            },
            civil_status: {
                type: "radio",
                label: "Civil Status",
                initialValues: (newEmployee?.civil_status?.name??"".toLowerCase())??"single",
                options: [
                    { value: 'SINGLE', label: "Single" },
                    { value: 'MARRIED', label: "Married" },
                    { value: 'WIDOW', label: "Widow/Widower" },
                    { value: 'SEPARATED', label: "Separated/Annuled" },
                    { value: 'LIVE_IN', label: "Living with Partner" },
                ],
                settings: {
                    labelField: "label",
                    valueField: "value",
                    inline: true,
                },
                validator: () => Yup.string().nullable().required().label(' ')
            },
            region: {
                type: "text",
                label: "Region",
                initialValues: newEmployee?.residence?.region?.name??"",
                validator: () => Yup.string().required().nullable().label(' '),
            },
            province: {
                type: "text",
                label: "Province",
                initialValues: newEmployee?.residence?.province?.name??"",
                validator: () => Yup.string().required().nullable().label(' '),
            },
            city: {
                type: "text",
                label: "City",
                initialValues: newEmployee?.residence?.city?.name??"",
                validator: () => Yup.string().required().nullable().label(' '),
            },
            barangay: {
                type: "text",
                label: "Barangay",
                initialValues: newEmployee?.residence?.barangay?.name??"",
                validator: () => Yup.string().required().nullable().label(' '),
            },
            street: {
                type: "text",
                label: "Street",
                initialValues: newEmployee?.residence?.street??"",
                validator: () => Yup.string().required().nullable().label(' '),
            },
            building_info: {
                type: "text",
                label: "House/Building Number",
                initialValues: newEmployee?.residence?.building_info??"",
                validator: () => Yup.string().required().nullable().label(' '),
            },
            department: {
                type: "text",
                label: "Department",
                initialValues: newEmployee.department??"",
                validator: () => Yup.string().nullable().label(' '),
            },
            allergies: {
                type: "autocomplete",
                label: "Allergy",
                initialValues: newEmployee?.allergies??[],
                options: allergies,
                settings: {
                    default: "",
                    multiple: true,
                    labelField: "label",
                    valueField: "id",
                },
                validator: () => Yup.array().label(' '),
                fieldProps:{
                    getOptionSelected: (option, t) => {
                        return option.id === (t?.allergy_id ?? t?.id)
                    }
                }
            },
            comorbidities: {
                type: "autocomplete",
                label: "Comorbidity",
                initialValues: newEmployee?.comorbidities??[],
                options: comorbidities,
                settings: {
                    default: "",
                    multiple: true,
                    labelField: "label",
                    valueField: "id",
                },
                validator: () => Yup.array().label(' '),
                fieldProps:{
                    getOptionSelected: (option, t) => {
                        return option.id === (t?.comorbidity_id ?? t?.id)
                    }
                }
            },
            strategic_business_unit: {
                type: "text",
                label: "Strategic Business Unit",
                initialValues: "" || newEmployee.strategic_business_unit || user.user?.corporate?.strategic_business_unit?.label,
                validator: () => Yup.string().nullable().label(' '),
            },
            business_unit: {
                type: "text",
                label: "Business Unit",
                initialValues: newEmployee.business_unit??"",
                validator: () => Yup.string().nullable().label(' '),
            },
            avatar: {
                type: 'component',
                component: () => <Avatar alt={`${editHash}`} src={imageUrl} className={classes.largeAvatar} />
            },
            external_employer: {
                type: "text",
                label: "Third Party Employer",
                initialValues: newEmployee.external_employer??"",
                validator: () => {
                    return (isInternal === PEOPLE_TYPE.THIRDPARTY) ? Yup.string().nullable().required().label(' '): null
                },
            },
            primary_employee_company_id: {
                type: "autocomplete",
                label: "Primary Employee Company ID",
                // initialValues: newEmployee.primary_employee_id??"",
                initialValues: autocompleteEmployeeList.find(v=>v.employee_id ===newEmployee?.primary_employee_id??""),
                validator: () => {
                    return (isInternal === PEOPLE_TYPE.DEPENDENTS) ? Yup.mixed().nullable().required().label(' '): null
                },
                options: autocompleteEmployeeList,
                settings: {
                    default: "",
                    multiple: false,
                    labelField: "name",
                    valueField: "employee_id",
                },
                fieldProps:{
                    onInputChange: debounce((event, v) => {
                        setAutocompleteEmployeeSearch(v)
                    }, 500),
                    // getOptionSelected: (option, t) => {
                    //     const ret = option.employee_id === t
                    //     return ret
                    // },
                }
            },
            relationship: {
                type: "select",
                label: "Relationship to Primary",
                initialValues: newEmployee.dependent_type??"",
                validator: () => {
                    return (isInternal === PEOPLE_TYPE.DEPENDENTS) ? Yup.string().nullable().required().label(' '): null
                },
                options: relationships,
                settings: {
                    default: "",
                    multiple: false,
                    labelField: "label",
                    valueField: "id",
                },
            },
            first_dose_date: {
                type: "date",
                label: "Date of first dose",
                inputProps: {
                    max: moment().format('YYYY-MM-DD')
                },
                initialValues: newEmployee?.vaccine?.first_dose_date??"",
                useLocalTime: true,
                validator: () => Yup.date().label(' ').when('vaccine', {
                    is: (val) => isEmpty(val),
                    then: Yup.date().nullable(),
                    otherwise: Yup.date().required().max(new Date(), `No later than ${moment(new Date()).format(
                            "L"
                    )}`)
                }),

            },
            second_dose_date: {
                type: "date",
                label: "Date of second dose",
                inputProps: {
                    max: moment().format('YYYY-MM-DD')
                },
                initialValues: newEmployee?.vaccine?.second_dose_date??"",
                useLocalTime: true,
                validator: () => Yup.date().nullable().max(new Date(), `No later than ${moment(new Date()).format(
                            "L"
                )}`),
            },
            vaccine: {
                type: "select",
                label: "COVID Vaccine",
                initialValues: newEmployee?.vaccine?.brand?? "",
                options: [{label: 'None', name:""}, ...vaccines],
                settings: {
                    default: "",
                    multiple: false,
                    labelField: "label",
                    valueField: "name",
                },
                validator: () => Yup.string().nullable(),
                fieldProps: {
                    className: classes.default,
                },
            },
            dose_date: {
                type: "date",
                label: "Date administered",
                inputProps: {
                    max: moment().format('YYYY-MM-DD')
                },
                readOnly: newEmployee?.flu_vaccine?.source==='Zennya',
                initialValues: newEmployee?.flu_vaccine?.dose_date??null,
                useLocalTime: true,
                validator: () => Yup.date().nullable().max(new Date(), `No later than ${moment(new Date()).format(
                            "L"
                )}`),
            },
            flu_vaccine: {
                type: "text",
                label: "Flu Vaccine",
                initialValues: newEmployee?.flu_vaccine?.brand?? "",
                // options: [{label: 'None', name:""}, ...vaccines],
                readOnly: newEmployee?.flu_vaccine?.source==='Zennya',
                settings: {
                    default: "",
                    multiple: false,
                    labelField: "label",
                    valueField: "name",
                },
                validator: () => Yup.string().nullable(),
                fieldProps: {
                    className: classes.default,
                },
            },
            doses: {
                type: "fieldarray",
                label: "Vaccine History",
                inline: true,
                initialValues: filterUserVaccines(newEmployee?.vaccines??[]),
                formLayout:["vaccine", "doseDate", ["cbcrId", "sourceLocation", "batchNumber", "lotNumber"]],
                formValueTemplate: {
                    doseDate:"",
                },
                validator:() =>
                    Yup.array().of(
                        Yup.object().shape({
                            vaccine: Yup.string().required().label(' '),
                            doseDate: Yup.date().required().label(' '),
                            cbcrId: Yup.string().nullable().label(' '),
                            sourceLocation: Yup.string().nullable(),
                            batchNumber: Yup.string().nullable(),
                            lotNumber: Yup.string().nullable(),
                        })
                    )
                ,
                formTemplate: {
                    vaccine: {
                        type: "select",
                        label: "COVID Vaccine",
                        initialValues: "",
                        options: [{label: 'None', name:""}, ...vaccines],
                        settings: {
                            default: "",
                            multiple: false,
                            labelField: "label",
                            valueField: "name",
                        },
                        fieldProps: {
                            className: classes.default,
                        },
                    },
                    doseDate: {
                        type: "date",
                        label: "Date administered",
                        inputProps: {
                            max: moment().format('YYYY-MM-DD')
                        },
                        initialValues: "",
                        useLocalTime: true,
                    },
                    cbcrId: {
                        type: "text",
                        label: "CBCR ID",
                        initialValues: "",
                        validator: () => Yup.string().nullable().label(' '),
                    },
                    sourceLocation: {
                        type: "text",
                        label: "LGU/PrivateHSP",
                        initialValues: "",
                    },
                    batchNumber: {
                        type: "text",
                        label: "Batch Number",
                        initialValues: "",
                    },
                    lotNumber: {
                        type: "text",
                        label: "Lot Number",
                        initialValues: "",
                    }
                },
                form:[]
            }

        };

        return {...formElements}

    }, [newEmployee.company_id, newEmployee.first_name, newEmployee.middle_name, newEmployee.last_name, newEmployee.email, newEmployee.mobile_number, newEmployee.gender, newEmployee.birthdate, newEmployee.age, newEmployee.role, newEmployee.seniority, newEmployee.work_nature, newEmployee?.type, newEmployee?.civil_status?.name, newEmployee?.residence?.region?.name, newEmployee?.residence?.province?.name, newEmployee?.residence?.city?.name, newEmployee?.residence?.barangay?.name, newEmployee?.residence?.street, newEmployee?.residence?.building_info, newEmployee.department, newEmployee?.allergies, newEmployee?.comorbidities, newEmployee.strategic_business_unit, newEmployee.business_unit, newEmployee.external_employer, newEmployee.dependent_type, newEmployee?.vaccine?.first_dose_date, newEmployee?.vaccine?.second_dose_date, newEmployee?.vaccine?.brand, newEmployee?.flu_vaccine?.source, newEmployee?.flu_vaccine?.dose_date, newEmployee?.flu_vaccine?.brand, newEmployee?.vaccines, newEmployee?.primary_employee_id, isEmployeeSupplied, isInternal, allergies, comorbidities, user.user?.corporate?.strategic_business_unit?.label, autocompleteEmployeeList, relationships, vaccines, classes.default, classes.largeAvatar, editHash, imageUrl]);

    
    const SmallAvatar = withStyles((theme) => ({
        root: {
            width: 22,
            height: 22,
            border: `${theme.palette.background.paper}`,
        },
    }))(CameraAltIcon);

    function handleImageClick() {
        document.getElementById("selectImage").click()
    }

    function handleFileSelection(event) {
        const file = event.target.files[0]

        setImageFile(file)

        const reader  = new FileReader();

        reader.addEventListener("load", function () {
            setImageUrl(reader.result)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function filterUserVaccines(vaxx) {
        return vaxx.filter(v => v?.source === "USER")
    }

    function filterNonUserVaccines(vaxx) {
        return vaxx.filter(v => v?.source !== "USER")
    }

    useEffect(() => {
        if ((employee && employee.constructor === Object && Object.keys(employee).length > 0) && !isEmployeeSupplied) {
            let employeeType
            switch (employee.type) {
                case 'DEPENDENT':
                    employeeType = PEOPLE_TYPE.DEPENDENTS
                    break;
                case 'EXTERNAL':
                    employeeType = PEOPLE_TYPE.THIRDPARTY
                    break
                default:
                    employeeType = PEOPLE_TYPE.WORKERS
                    break;
            }
            setIsInternal(employeeType)
            setNewEmployee({...employee, 
                worker_type: employee.work_nature,
                type: employeeType,
            });
            
            setIsEmployeeSupplied(true)
            
            if (employee.profile_pic_url) {
                setImageUrl(employee.profile_pic_url)
            }

        }

        if (user.user.corporate.strategic_business_unit && !newEmployee.strategic_business_unit) {
            const sbu = user.user.corporate.strategic_business_unit

            setNewEmployee(data => ({
                ...data,
                strategic_business_unit: sbu.label
            }))
        }
    }, [employee, editHash, isEmployeeSupplied, user.user.corporate.strategic_business_unit, newEmployee.strategic_business_unit])

    const formatParams = useCallback(
        async ({doses,allergies, comorbidities, region, primary_employee_company_id, ...values}) => {
            const dateFormat = moment(values.birthdate).startOf('d').format()
            const missingComorbidity = (newEmployee?.comorbidities??[]).filter(c=>!comorbidities.includes(c)).map(c=>{
                return {...c,
                    id: c.id,
                    has_comorbidity: false
                }
            })
            const mappedComorbidity = comorbidities.map(({label, ...c})=>{
                return {...c,
                    id: c.id,
                    has_comorbidity: true
                }
            })
            
            const missingAllergy = newEmployee?.allergies.filter(c=>{
                return !allergies.includes(c)
            }).map(c=>{
                return {...c,
                    id: c.id,
                    has_allergy: false
                }
            })
            const mappedAllergy = allergies.map(({label, type, ...c})=>{
                return {...c,
                    id: c.id,
                    
                    has_allergy: true}
            })

            // const mappedRegion = await mapRegionNameToCode(region)

            let type = {type: 'EMPLOYEE'}
            switch (isInternal) {
                case PEOPLE_TYPE.DEPENDENTS:
                    type = {
                        type:'DEPENDENT',
                        primary_employee_company_id: primary_employee_company_id?.employee_id
                    }
                    break;
                case PEOPLE_TYPE.THIRDPARTY:
                    type = {type:'EXTERNAL'}
                    break
                default:
                    break;
            }

            const systemDoses = filterNonUserVaccines(newEmployee?.vaccines??[])
            
            const params = {...values, 
                ...type,
                birthdate: values.birthdate?dateFormat:null,
                comorbidities: [...mappedComorbidity, ...missingComorbidity],
                allergies: [...missingAllergy, ...mappedAllergy],
                region: region, 
                vaccine_history: {doses: ([...doses]??[]).map(dose=>{
                    return {
                        vaccine:dose?.vaccine,
                        date: dose?.doseDate,
                        cbcr_id: dose?.cbcrId,
                        source_location: dose?.sourceLocation,
                        batch_number: dose?.batchNumber,
                        lot_number: dose?.lotNumber,

                    }
                })}

            }
            
            action({...params})
            // setNewEmployee({})
        },
        [action, isInternal, newEmployee?.allergies, newEmployee?.comorbidities],
    );
    const FormConsumer = ({onError}) => {
        const formikObject = useFormikContext();
        const {values, initialValues, setValues, setFieldValue, ...formik} = formikObject
 
        useEffect(() => {
            // console.log("📢[SaveEmployeeDialog.js:654]: ", values);
            if (formik.errors) {
                onError(formik.errors)
            }
        }, [formik.errors, onError]);
        useEffect(() => {
            const dirty = formik.dirty
            if (!isEmpty(address)) {
                setFieldValue('province', address?.province??"", true)
                setFieldValue('city', address?.city??"", true)
                setFieldValue('barangay', address?.barangay??"", true)
                setFieldValue('region', address?.region??"", true)
                setAddress()
            }
        }, [address, formik.dirty]);
        return null;
    };
    return (
        <Dialog
            maxWidth={"md"}
            fullWidth
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="add-employee-dialog"
            disableEnforceFocus
            disableAutoFocus
            keepMounted={false}
        >
            <DialogTitle
                id="add-employee-dialog-title"
                onClose={ () => setModal(false)}
                disableTypography
            >
                <Grid container justify={"space-between"}>
                    <Grid item xs={6}>
                        <Typography className={clsx(classes.bold)} variant={"h6"}>
                            { title }
                        </Typography>
                        <span className={clsx(classes.textDefaultColor, classes.subtitle1)}>
                                { subtitle }
                        </span>
                    </Grid>
                    <Grid item xs={6} className={classes.textRight}>
                        <IconButton onClick={ () => setModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={clsx(classes.grow)} dividers>
                {/* webcompo form */}
                <FormBuilder 
                    variant="outlined"
                    formLabel={' '}
                    columns={2}
                    submitLabel={actionText}
                    formFactor="default"
                    formLayout={formLayout}
                    form={formSetup}
                    onSubmit={(values)=> {
                        formatParams(values)
                    }}
                    onChange={(values) => {
                        setFormValues(values);
                    }}
                >
                    <FormConsumer onError={err=>setErrors(err)}/>
                </FormBuilder>
                
            </DialogContent>
        </Dialog>
    )
}
