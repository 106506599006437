import { Typography } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import voca from "voca";
import ProgressBar from "@ramonak/react-progress-bar";

const useStyles = makeStyles((theme) => ({
  base: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    
  },
  root: {
    height: 10,
    borderRadius: 5,
    width: "100%",
    position: "relative",
    backgroundColor: (props) => {
      return props.outOfRangeColor ?? theme.palette.primary.light;
    },
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: (props) => {
      return props.progresscolor;
    },
  },
  overLabel: {
    fontSize: "0.75rem",
  },
}));

const rangeColor = ["#78BDBA", "#F59D6B", "#E8685E"]; //set the number of section colors (0 to 1/3, 1/3 to 2/3, 2/3 to 1)

const SeverityProgress = ({
  value = 9,
  maxValue = 30,
  unit = "days",
  chunk = 3,
}) => {
  const progresscolor = useMemo(() => {
    const range = Math.floor(value / (maxValue / rangeColor.length));
    return range > rangeColor.length ? "#FF685E" : rangeColor[range];
  }, [maxValue, value]);

  const outOfRangeColor = useMemo(() => {
    const range = Math.floor(value / (maxValue / rangeColor.length));
    return range > rangeColor.length ? "#FF685E" : null;
  }, [maxValue, value]);

  const progressValue = useMemo(() => {
    const prog = Math.round((value / maxValue) * 100);
    if (prog <= 15) {
      return 15;
    }
    return prog > 100 ? 100 : prog;
  }, [maxValue, value]);

  const percentileValue = useMemo(() => {
    return Math.round((value / maxValue) * 100);
  }, [maxValue, value]);

  const classes = useStyles({ outOfRangeColor, progresscolor });

  // return (
  //   <div className={classes.base}>
  //     <Typography variant="overline">{value}</Typography>
  //     <LinearProgress
  //       classes={{
  //         root: classes.root,
  //         bar: classes.bar,
  //       }}
  //       outOfRangeColor={outOfRangeColor}
  //       progresscolor={progresscolor}
  //       variant="determinate"
  //       value={Math.round((value / maxValue) * 100)}
  //     />
  //     <Typography variant="caption">
  //       {maxValue}
  //       {voca.titleCase(unit)}
  //     </Typography>
  //   </div>
  // );
  return (
    <div className={classes.base}>
      <ProgressBar
        completed={progressValue}
        bgColor={progresscolor}
        width={"100%"}
        customLabel={
          percentileValue > 100 ? `${value - maxValue} days over` : `${value}`
        }
        labelSize={percentileValue > 100 ? ".75em" : "1em"}
        labelAlignment="left"
      />
      <Typography variant="caption">
        Valid for: {maxValue} {voca.titleCase(unit)}
      </Typography>
     
    </div>
  );
};

export { SeverityProgress };
