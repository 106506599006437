import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link, Button, FormControl, Grid, TextField, InputAdornment, LinearProgress,
     Typography, 
     Menu,
     MenuItem} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import DataTable from './../components/DataTable';
import corporateEventsService from './../services/corporate-events.service';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import CohortDetails from "./cohortDetails";
import CohortsList from './cohortsList';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { ArrowDropDown } from '@material-ui/icons';
import { PEOPLE_TYPE } from '../employees/employeeList';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      maxWidth: '1440px'
    },
    officeInfoTitle: {
      padding: 10
    },
    officeInfoSection: {
      padding: 10
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    textLeft: {
        textAlign: 'left'
    },
    marginSpacer: {
        marginRight: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        width: '45%',
    },
    linkColor: {
        color: theme.palette.link.main
    },
    btnTextSize: {
        fontSize: '1.25rem'
    },
    rectangularChip: {
        padding: 5,
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '0.75rem'
    }
}));

const Cohorts = (props) => {
    const classes = useStyles();
    const [openCohortDetails, setOpenCohortDetails] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [addingMode, setAddingMode] = useState(false);
    const [focusedCohort, setFocusedCohort] = useState(null);
    const [listIterated, setListIterated] = useState(0);
    const [cohortType, setCohortType] = useState(PEOPLE_TYPE.WORKERS);
    const [peopleType, setPeopleType] = useState(PEOPLE_TYPE.WORKERS);
    const [ state, setState ] = useState({
        search_text: ''
    });
    const handleOpenAddCohort = ({pType}) => {
        setFocusedCohort(null)
        setAddingMode(true);
        setOpenCohortDetails(true);
        setPeopleType(pType);
    }

    const handleOpenCohortDetails = () => {
        setOpenCohortDetails(true);
    }

    const handleFocusedCohort = (cohort) => {
        setFocusedCohort(cohort);
        setOpenCohortDetails(true);
    }

    const handleCloseCohortDetails = () => {
        setOpenCohortDetails(false);
        setAddingMode(false);
        setListIterated(listIterated + 1);
    }
    
    const downloadCohortEmployees = ({pType}) => {
        let types = ''
        switch (pType) {
            case PEOPLE_TYPE.WORKERS:
                types = 'EMPLOYEE'
                break;
            case PEOPLE_TYPE.DEPENDENTS:
                types = 'DEPENDENT'
                break
            case PEOPLE_TYPE.THIRDPARTY:
                types = 'EXTERNAL'
                break       
            default:
                break;
        }
        corporateEventsService.downloadCohorts(props.company, {types}).then(
            (response) => {
                var downloadFile = `Cohorts-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
                setDownloadLoading(false);
                var file = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                });
                var saving = document.createElement('a');
                saving.href = URL.createObjectURL(file);
                saving.download = (
                    downloadFile
                );
                saving.click();
            }
        )
    }

    const handleChange = (name) => (cohort) => {
        setState({
            ...state,
            [name]: cohort.target.value
        })
    }  

    return (
        <div className={classes.root}>

            { openCohortDetails ?
                <CohortDetails peopleType={peopleType} company={props.company} open={openCohortDetails} handleClose={handleCloseCohortDetails} isAdding={addingMode} selectedCohort={focusedCohort} ></CohortDetails>
            : undefined}

            <Grid container spacing={3}>
                <Grid item xs={6} container justify="flex-start" alignItems="flex-start">
                    <Typography variant="h5" style={{ fontWeight: 700}}>
                        Cohorts List
                    </Typography>
                </Grid>

                <Grid item xs={3} container justify="flex-end" alignItems="flex-end">
                    <PopupState variant="popper" popupId="downloadWorkersMenu">
                        {(popupState) => (
                            <>
                                <Button variant="outlined"
                                    color="primary"
                                    endIcon={<ArrowDropDown/>}
                                    
                                    disabled={downloadLoading}
                                    {...bindTrigger(popupState)}
                                    // onClick={downloadCohortEmployees}
                                >
                                    Download Workers
                                </Button>
                                <Menu
                                    {...bindMenu(popupState)}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                >
                                    <MenuItem onClick={()=> {
                                        popupState.close()
                                        downloadCohortEmployees({pType: PEOPLE_TYPE.WORKERS})
                                    }}>Workers List</MenuItem>
                                    <MenuItem onClick={()=> {
                                        popupState.close()
                                        downloadCohortEmployees({pType: PEOPLE_TYPE.DEPENDENTS})
                                    }}>Dependents List</MenuItem>
                                    <MenuItem onClick={()=> {
                                        popupState.close()
                                        downloadCohortEmployees({pType: PEOPLE_TYPE.THIRDPARTY})
                                    }}>Third Party List</MenuItem>
                                    <MenuItem onClick={()=> {
                                        popupState.close()
                                        downloadCohortEmployees({pType: null})
                                    }}>All</MenuItem>
                                    
                                </Menu>
                            </>
                        )}
                    </PopupState>

                </Grid>
                

                <Grid item xs={3} container justify="flex-end" alignItems="flex-end">
                    <PopupState variant="popper" popupId="downloadWorkersMenu">
                        {(popupState) => (
                            <>
                                <Button variant="outlined"
                                    endIcon={<ArrowDropDown/>}
                                    color="primary"
                                    
                                    disabled={downloadLoading}
                                    {...bindTrigger(popupState)}
                                    // onClick={handleOpenAddCohort}
                                >
                                    Create a new Cohort 
                                </Button>
                                <Menu
                                    {...bindMenu(popupState)}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                >
                                    <MenuItem onClick={()=> {
                                        popupState.close()
                                        handleOpenAddCohort({pType: PEOPLE_TYPE.WORKERS})
                                    }}>Workers Cohort</MenuItem>
                                    <MenuItem onClick={()=> {
                                        popupState.close()
                                        handleOpenAddCohort({pType: PEOPLE_TYPE.DEPENDENTS})
                                    }}>Dependents Cohort</MenuItem>
                                    <MenuItem onClick={()=> {
                                        popupState.close()
                                        handleOpenAddCohort({pType: PEOPLE_TYPE.THIRDPARTY})
                                    }}>Third Party Cohort</MenuItem>
                                </Menu>
                            </>
                        )}
                    </PopupState>
                </Grid>


                <Grid item xs={12}>
                    <CohortsList {...props} iterator={listIterated} handleFocusedCohort={handleFocusedCohort}/>
                </Grid>

            </Grid>
        </div>
    )
}

export default Cohorts;