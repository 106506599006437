import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Main from './../shared/Main';
import { UserProvider, UserContext } from "../context/UserContext"
import ErrorCatcher from './../errors/errorBoundary';

export default function AuthenticatedRoute(props) {
    const {component: Component, ...rest} = props

    return (
        <Route {...rest} render={props => {
            if (!localStorage.getItem('currentUser')) {
                return <Redirect exact to={"/login"}/>
            }

            return (
                <UserProvider>
                    <UserContext.Consumer>
                        {
                            value => (
                                <Main user={value}>
                                    <ErrorCatcher>
                                        <Component
                                            company={value.user.settings.corporate_id}
                                            logo={value.user.settings.corporate_logo_url}
                                            isAdmin={value.user.isAdmin}
                                            {...rest}
                                            {...props}
                                            
                                        />
                                    </ErrorCatcher>
                                </Main>
                            )
                        }
                    </UserContext.Consumer>
                </UserProvider>
            )
        }}/>
    )
}
