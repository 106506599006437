import { Avatar, Card, Chip, Divider, Link, makeStyles, Typography } from "@material-ui/core";
import { LocalHospital } from "@material-ui/icons";
import moment from "moment";
import { SelectColumn, SelectCellFormatter } from "react-data-grid";
import { PEOPLE_TYPE } from "./employeeList";
import { Link as RouterLink } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import { SeverityProgress } from "../components/SeverityProgress";
import isEmpty from "lodash.isempty";
import React from 'react';

import {getOrdinal} from '../utils/vaccineUtils'


const risks = 
  [
    {
      label: "High",
      value: "HIGH",
    },
    {
      label: "Low",
      value: "LOW",
    },
    {
      label: "Moderate",
      value: "MODERATE",
    },
    {
      label: "Unknown",
      value: "UNKNOWN",
    },
  ]

function gridColumns({
  hasRisk, hasPeopleTypeFilter,
  isHealth,
  relationOptions = [],
  peopleType,
  history,
  onAllRowsSelectionChange = () => {},
  allRowsSelected = false,
  vaccineData,
  corpHealthReport
}) {
  const baseColumns = [
    {
      ...SelectColumn,
      columnHeaderRenderer: (props) => {
        return (
          <SelectCellFormatter
            aria-label="Select All"
            value={allRowsSelected}
            onChange={onAllRowsSelectionChange}
          />
        );
      },
    },
    {
      key: "id",
      colId: "id",
      name: "ID",
      hidden: true,
      width: -1,
    },
    {
      key: "employee_id",
      colId: "employee_id",
      name: "Employee ID",
      hidden: true,
      width: -1,
    },
    // isHealth && 
    // {
    //   key: "risk_factors",
    //   colId: "risk_factors",
    //   name: "Risk Factor",
    //   hidden: true,
    //   width: -1,
    //   filter: {
    //     type: "autocomplete",
    //     labelField: "label",
		// 		valueField: "value",
    //     options: risks,
    //   },
    // },
    
    {
      key: "company_id",
      colId: "company_id",
      name: "Company ID",
      sortable: true,
      frozen: true,
      resizable:true,
      minWidth: 120,
      // cellRenderer({ row }) {
      //   return row.id ? (
      //     <Link
      //       component={RouterLink}
      //       to={`/workers/${row.employee_id}`}
      //     >
      //       <span>{row.company_id}</span>
      //     </Link>
      //   ) : (
      //     <span>{row.company_id}</span>
      //   );
      // },
    },
    peopleType === PEOPLE_TYPE.DEPENDENTS && {
      key: "primary_employee_id",
      colId: "primary_employee_id",
      name: "Primary",
      sortable: true,
      minWidth: 200,
      tooltip({row}) {
        return `${row?.primary_employee?.lastName}, ${row?.primary_employee?.firstName}`
      },
      cellRenderer({ row }) {
        return row?.primary_employee ? (
          <Link
            component={RouterLink}
            to={`/workers/${row?.primary_employee?.id}`}
          >
          
            <Avatar
              src={row?.primary_employee?.profile_picture_url}
              alt={"user_logo"}
              style={{ width: 30, height: 30 }}
            />
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {row?.primary_employee?.lastName}, {row?.primary_employee?.firstName}
            </Typography>
          
          </Link>
        ) : (
          <span>{row.primary_employee_id}</span>
        );
      },
    },
    {
      key: "name",
      colId: "name",
      name: "Name",
      minWidth: 200,
      resizable: true,
      sortable: true,
      frozen: true,
      // filter: {
      //   type: "text",
      //   label: "Contains",
      //   default: "",
      // },
      cellRenderer({ row }) {
        return row.id ? (
          <Link
            component={RouterLink}
            to={`/workers/${row.employee_id}`}
          >
          <ListItem dense>
            <Avatar
              src={row.profile_picture_url}
              alt={"user_logo"}
              style={{ width: 30, height: 30 }}
            />
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {row.name}
            </Typography>
          </ListItem>
          </Link>
        ) : (
          <span>{row.name}</span>
        );
      },
    },
    isHealth && 
    {
      key: "flu_vaccine",
      colId: "flu_vaccine",
      name: "Flu Vaccination",
      minWidth: 200,
      resizable: true,
      tooltip({row}) {
        if(row?.flu_vaccine===null) return false
        return <>
                  <p>{`Brand: ${row?.flu_vaccine?.brand}`}</p>
                  <p>{`Dose Date: ${moment(row?.flu_vaccine?.dose_date).format('LL')}`}</p>
                  <p>{`Source: ${row?.flu_vaccine?.source}`}</p>
                  </>
      },
      cellRenderer({ row }) {
        if(row.flu_vaccine===null) return  <Typography variant="caption" component="span" color="textSecondary">No flu vaccination records</Typography>
        return <span>{`${moment(row?.flu_vaccine?.dose_date).format("MMM DD, YYYY")} - ${row.flu_vaccine.brand}`}</span>
      },
    },
    peopleType === PEOPLE_TYPE.DEPENDENTS && {
      key: "relationship",
      colId: "relationship",
      name: "Relation",
      sortable: false,
      resizable: true,
      filter: {
        type: "autocomplete",
        labelField: "label",
				valueField: "value",
        options: relationOptions,
      },
    },
    isHealth && 
    {
      key: "vaccination_status",
      colId: "vaccination_status",
      name: "Vaccination Status",
      minWidth: 150,
      resizable: true,
      cellRenderer({row}) {
        const style =
          row?.isVaccineCompleted?.completedAll && row?.isVaccineCompleted?.completedSome
            ? {
                backgroundColor: "#C4EDC6",
                color: "#54A557",
                border: "1px solid #C4EDC6",
                borderRadius: 5,
                width:'100%'
              }
            : (row?.isVaccinated || row?.isVaccineCompleted.completedSome)? {
                backgroundColor: "#DBEDFF",
                color: "#6A99CA",
                border: "1px solid #DBEDFF",
                borderRadius: 5,
                width:'100%'
              }:{
                backgroundColor: "#FFDBD8",
                color: "#E8685E",
                border: "1px solid #FFDBD8",
                borderRadius: 5,
                width:'100%'
              };
        let label = ""
        if (row?.isVaccineCompleted?.completedAll && row?.isVaccineCompleted?.completedSome) {
          label = "VACCINATED - COMPLETE";
        } else if (row?.isVaccineCompleted?.completedSome) {
          label = "VACCINATED - MIXED";
        } else if (row?.isVaccinated) {
          label = "VACCINATED - INCOMPLETE";
        } else {
          label = "NOT VACCINATED";
        }
        return (<>
          <Chip
            style={style}
            label={
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                {label}
              </Typography>
            }
          />
          </>
        );
      },
      noTooltip: true,
      // filter: {
      //   type: "option",
      //   label: "Type",
      //   options: [
      //     {
      //       label: "Vaccinated",
      //       value: "vaccinated",
      //     },
      //     {
      //       label: "Not Vaccinated",
      //       value: "notvaccinated",
      //     },
      //     {
      //       label: "First Dose",
      //       value: "firstdose",
      //     },
      //   ],
      // },
    },
    isHealth && 
    {
      key: 'covid_vaccination_eligibility',
      colId: 'covid_vaccination_eligibility',
      name: 'Next Vaccination Eligibility',
      minWidth: 150,
      sortable: true,
      resizable: true,
      tooltip({row}) {
        if(!row?.isVaccinated){return null}
        const style = {
          content:{
            fontSize:14
          },
          container:{
            paddingLeft:10,
            paddingRight:10,
            display:'flex',
            flexDirection:'column',
          }
      }
      
        return <div style={style.container}>
          {
            row?.vaccines?.slice(0).reverse().map((vaccine,index)=>{
              return (
                <React.Fragment key={index}>
                  <Typography variant="caption">{`${getOrdinal(index + 1)} Dose: ${vaccine.vaccine}`}</Typography>
                  <Typography variant="caption">{`Date: ${moment(vaccine?.doseDate).format('MM/DD/YY')}`}</Typography>
                  <Divider/>
                </React.Fragment>
              )
            })
          }
        </div>
        // return <div style={style.container}>
        //   {row?.vaccineTimeline?.first_dose_date? <>
        //     <p style={style.content}>{`First Dose: ${row?.vaccine?.brand}`}</p>
        //     <p style={style.content}>{`Date: ${moment(row?.vaccineTimeline?.first_dose_date).format('MM/DD/YY')}`}</p>
        //     <p style={style.content}>{`Source: ${row?.vaccine?.source||'NA'}`}</p> 
        //   </>:null}
        //   <Divider/>
        //   {row?.vaccineTimeline?.second_dose_date? <>
        //     <p style={style.content}>{`Second Dose: ${row?.vaccine?.brand}`}</p>
        //     <p style={style.content}>{`Date: ${moment(row?.vaccineTimeline?.second_dose_date).format('MM/DD/YY')}`}</p>
        //     <p style={style.content}>{`Source: ${row?.vaccine?.source||'NA'}`}</p> 
        //   </>:null}
        // </div>
      }
    },
    isHealth && {
      key: 'vaccine_countdown',
      colId: 'vaccine_countdown',
      name: 'Next Dose Countdown',
      minWidth: 150,
      resizable: true,
      cellRenderer({ row }) {
        if(row?.vaccineTimeline?.nextDoseType?.endsWith('Booster')) {
          const end = moment(row.vaccineTimeline?.second_dose_date || row.vaccineTimeline?.first_dose_date); // another date
          const days = moment().diff(end, 'days')
          const vaccFrequency = row?.vaccineTimeline.nextDoseFrequency
          if (vaccFrequency <= 0 ) {
            return <Typography variant="caption" component="span" color="textSecondary">NA</Typography>
          }  
          return (
            <SeverityProgress 
              value={days}
              maxValue={vaccFrequency} 
              unit="days" 
            />
          )
        }
        if( ( row.vaccineTimeline?.first_dose_date ? 1 : 0 ) ^ ( row.vaccineTimeline?.second_dose_date ? 1 : 0 ) ) {
          const end = moment(row.vaccineTimeline?.first_dose_date); // another date
          const days = moment().diff(end, 'days')
          const vacc = vaccineData.find(v => v.name === row.vaccine?.brand)
          const vaccFrequency = vacc?.frequency
          if (vaccFrequency <= 0 ) {
            return <Typography variant="caption" component="span" color="textSecondary">NA</Typography>
          }  
          return (
            <SeverityProgress 
              value={days}
              maxValue={vaccFrequency} 
              unit="days" 
            />
          )
        }
        return <Typography variant="caption" component="span" color="textSecondary">NA</Typography>
      }
    },
    !isHealth && peopleType === PEOPLE_TYPE.THIRDPARTY && {
      key: "third_party_employer",
      colId: "third_party_employer",
      name: "3rd Party Employer",
      sortable: false,
      resizable: true,
      minWidth: 160
    },
    !isHealth && {
      key: "role",
      colId: "role",
      name: "Role",
      sortable: true,
      resizable: true,
      hidden:true,
    },
    !isHealth && {
      key: "business_unit",
      colId: "business_unit",
      name: "Business Unit",
      sortable: true,
      resizable: true,
      hidden:true,
    },
    !isHealth && {
      key: "department",
      colId: "department",
      name: "Department",
      sortable: true,
      resizable: true,
      hidden:true,
    },
    !isHealth && {
      key: "worker_type",
      colId: "worker_type",
      name: "Worker Type",
      sortable: false,
      resizable: true,
      hidden:true,
      filter: {
        type: "autocomplete",
        labelField: "label",
				valueField: "value",
        options: [
          {
            label: "Internal",
            value: "Internal",
          },
          {
            label: "Third-Party",
            value: "Third-Party",
          },
        ],
      },
    },
    !isHealth && {
      key: "work_nature",
      colId: "work_nature",
      name: "Work Nature",
      sortable: true,
      hidden:true,
      resizable: true,
      // filter: {
      //   type: "option",
      //   label: "Nature",
      //   options: [
      //     {
      //       label: "Office",
      //       value: 0,
      //     },
      //     {
      //       label: "Field",
      //       value: 1,
      //     },
      //   ],
      // },
    },
    !isHealth && {
      key: "status",
      colId: "status",
      name: "Link Status",
      sortable: false,
      resizable: true,
      width: 125,
      cellRenderer(props) {
        const style =
          props?.row.status === "LINKED"
            ? {
                backgroundColor: "#C4EDC6",
                color: "#54A557",
                border: "1px solid #C4EDC6",
                borderRadius: 5,
              }
            : {
                backgroundColor: "#FFDBD8",
                color: "#E8685E",
                border: "1px solid #FFDBD8",
                borderRadius: 5,
              };
        const label = props?.row.status === "LINKED" ? "LINKED" : "NOT LINKED";
        return (
          <Chip
            size="small"
            style={style}
            label={
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                {label}
              </Typography>
            }
          />
        );
      },

      noTooltip: true,
      filter: {
        type: "autocomplete",
        labelField: "label",
				valueField: "value",
        options: [
          {
            label: "Linked",
            value: "Linked",
          },
          {
            label: "Not Linked",
            value: "Not Linked",
          },
        ],
      },
    },
    
    !isHealth && {
      key: "location",
      colId: "location",
      name: "Address",
      resizable: true,
      // filter: {
      //   type: "text",
      //   label: "Search",
      // },
      width: 250,
      cellRenderer({ row }) {
        return (
          <div className="doubleRow" style={{alignItems:'start'}} >{row?.location}</div>
        );
      },
    },
    !isHealth && {
      key: "gender",
      colId: "gender",
      name: "Gender",
      sortable: true,
      resizable: true,
    },
    !isHealth && {
      key: "age",
      colId: "age",
      name: "Age",
      sortable: true,
      resizable: true,
    },
    !isHealth && {
      key: "civil_status",
      colId: "civil_status",
      name: "Civil status",
      sortable: true,
      resizable: true,
    },
    // {
    //     key:"vaccine_firstdose",
    //     colId: "vaccine_firstdose",
    //     name: "Vaccine First Dose",
    //     minWidth: 200,
    //     resizable:true,
    //     tooltip({row}) {
    //       return row?.vaccine_firstdose??''
    //     },
    //     sortable:true,
    //     cellRenderer({row}) {
    //       return <Typography variant="body2" color={row?.vaccine_firstdose?'textPrimary':'textSecondary'}>{row?.vaccine_firstdose??'No vaccine record'}</Typography>
    //     }
    // },
    // {
    //     key:"vaccine_days",
    //     colId: "vaccine_days",
    //     name: "Days since first dose",
    //     minWidth: 100,
    //     resizable:true,
    //     noTooltip: true,
    //     sortable:true,
    // },
    // {
    //     key:"vaccine_seconddose",
    //     colId: "vaccine_seconddose",
    //     name: "Vaccine Second Dose",
    //     minWidth: 200,
    //     resizable:true,
    //     tooltip({row}) {
    //       return row?.vaccine_seconddose??''
    //     },
    //     sortable:true,
    //     cellRenderer({row}) {
    //       return <Typography variant="body2" color={row?.vaccine_seconddose?'textPrimary':'textSecondary'}>{row?.vaccine_seconddose??'No vaccine record'}</Typography>
    //     }
    // },
    // {
    //     key:"vaccine",
    //     colId: "vaccine",
    //     name: "Vaccine Status",
    //     minWidth: 140,
    //     resizable:true,
    //     noTooltip: true,
    //     sortable:true,
    //     cellRenderer({row}) {
    //         const vaccinated ={
    //             backgroundColor: "#C4EDC6",
    //             color: "#54A557",
    //             border: "1px solid #C4EDC6",
    //             borderRadius:5,
    //             fontWeight:700,
    //         }
    //         const notvaccinated ={
    //             backgroundColor: "#FFE3E1",
    //             color: "#ED867E",
    //             border: "1px solid #FFE3E1",
    //             borderRadius:5,
    //             fontWeight:700,
    //         }
    //         const firstdose ={
    //             backgroundColor: "#DBEDFF",
    //             color: "#86AED7",
    //             border: "1px solid #DBEDFF",
    //             borderRadius:5,
    //             fontWeight:700,
    //         }
    //         let vaccClass = {}
    //         let vaccStatusLabel = 'NOT VACCINATED'
    //         if (row?.vaccineTimeline?.first_dose_date && row?.vaccineTimeline?.second_dose_date) {
    //             vaccClass = vaccinated
    //             vaccStatusLabel = 'VACCINATED'
    //         } else if (row?.vaccineTimeline?.first_dose_date) {
    //             vaccClass = firstdose
    //             vaccStatusLabel = 'FIRST DOSE'
    //         } else {
    //             vaccClass = notvaccinated
    //             vaccStatusLabel = 'NOT VACCINATED'
    //         }
    //         return (
    //             <Chip
    //             size="small"
    //             style={vaccClass}
    //             label={
    //               <Typography variant="caption" style={{ fontWeight: 700 }}>
    //                 {vaccStatusLabel}
    //               </Typography>
    //             }
    //             />
    //         );
    //     } 
    // },
    isHealth && 
    {
      key: "allergies",
      colId: "allergies",
      name: "Allergies",
      sortable: true,
      resizable: true,
      minWidth: 200,
      // filter: {
      //   type: "option",
      //   label: "Type",
      //   options: [
      //     { value: "Drug", label: "Drug" },
      //     { value: "Food", label: "Food" },
      //     { value: "Insect", label: "Insect" },
      //     { value: "Latex", label: "Latex" },
      //     { value: "Mold", label: "Mold" },
      //     { value: "Pet", label: "Pet" },
      //     { value: "Pollen", label: "Pollen" },
      //   ],
      // },
      cellRenderer({ row }) {
        return (
          <div className="doubleRow" style={{alignItems:'start'}} >{row?.allergies}</div>
        );
      },
    },
    isHealth && 
    {
      key: "comorbidities",
      colId: "comorbidities",
      name: "Comorbidities",
      sortable: true,
      resizable: true,
      minWidth: 200,
      // filter: {
      //   type: "option",
      //   label: "Type",
      //   options: [
      //     { value: "Hypertension", label: "Hypertension" },
      //     { value: "Heart Disease", label: "Heart Disease" },
      //     { value: "Kidney Disease", label: "Kidney Disease" },
      //     { value: "Diabetes", label: "Diabetes" },
      //     { value: "Asthma", label: "Asthma" },
      //     { value: "Immunodeficiency", label: "Immunodeficiency" },
      //     { value: "Cancer", label: "Cancer" },
      //     { value: "Others", label: "Others" },
      //   ],
      // },
      cellRenderer({ row }) {
        return (
          <div className="doubleRow" style={{alignItems:'start'}} >{row?.allergies}</div>
        );
      },
    },
    isHealth && 
    {
      key: "test_status",
      colId: "test_status",
      name: "Test Status",
      sortable: true,
      resizable: true,
      width: 150,
      cellRenderer({ row }) {
        if(row.test_status===null) return null

        const style =
          row.test_status==='UNTESTED'
            ?{
              backgroundColor: "#DBEDFF",
              color: "#6A99CA",
              border: "1px solid #DBEDFF",
              borderRadius: 5,
              width:'100%'
            }
            :{
                backgroundColor: "#FFDBD8",
                color: "#E8685E",
                border: "1px solid #FFDBD8",
                borderRadius: 5,
                width:'100%'
              }
              // :{
              //   backgroundColor: "#FFDBD8",
              //   color: "#E8685E",
              //   border: "1px solid #FFDBD8",
              //   borderRadius: 5,
              //   width:'100%'
              // };
        return (
          <Chip
            style={style}
            label={
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                {row.test_status}
              </Typography>
            }
          />
        );
      },
    },
    isHealth && 
    {
      key: "test_type",
      colId: "test_type",
      name: "Test Type",
      sortable: true,
      resizable: true,
      minWidth: 200,
      cellRenderer({ row }) {
        return (
          <Typography variant="body2" color="textPrimary" className="doubleRow" style={{alignItems:'start'}} >{row?.test_type}</Typography>
        );
      },
    },
    isHealth && 
    {
      key: "test_result",
      colId: "test_result",
      name: "Test Result",
      sortable: true,
      resizable: true,
      minWidth: 200,
      cellRenderer({ row }) {
        return (
          <Typography variant="body2" color="textPrimary" className="doubleRow" style={{alignItems:'start'}} >{row?.test_result}</Typography>
        );
      },
    },
    isHealth && 
    {
      key: "validity_countdown",
      colId: "validity_countdown",
      name: "Validity Period",
      sortable: true,
      resizable: true,
      minWidth: 200,
      cellRenderer({ row }) {
        if (row?.test_expired) {
          return <Typography variant="caption" color="textSecondary" className="doubleRow" style={{alignItems:'start'}} >Expired last {moment(row?.test_expired).format("LL")}</Typography> 
        }
        if (row.test_validity) {
          return <SeverityProgress value={row.test_validity} maxValue={row.test_duration} unit="days" />
        }
        
        
      },
    },
  ];

  return [...baseColumns].filter((col) => !!col);
}

function gridRows(rows, covidReport) {
  const rowsFmt = rows.map((employee, index) => {
    let matchCovidReport = covidReport.filter(report=> report.worker_id===employee.id)
    const hasCovidReport = matchCovidReport.length>0
    if (!isEmpty(matchCovidReport)) {
      matchCovidReport = matchCovidReport[0]
    }
    let today = moment().startOf('day')
    let vaccine_days = moment().diff(moment(employee?.vaccineTimeline?.first_dose_date), 'days')
    let vaccine_firstdose = employee?.vaccineTimeline?.first_dose_date ? `${employee?.vaccine?.brand} - ${moment(employee?.vaccineTimeline?.first_dose_date).format('LL')}` :null
    let vaccine_seconddose = employee?.vaccineTimeline?.second_dose_date ? `${employee?.vaccine?.brand} - ${moment(employee?.vaccineTimeline?.second_dose_date).format('LL')}`:null
    let test_validity =  hasCovidReport?  moment(matchCovidReport?.valid_until).diff(today, 'days'): null
    let test_duration =  hasCovidReport?  moment(matchCovidReport?.valid_until).diff(moment(matchCovidReport?.test_date), 'days'):null
    let test_still_valid = hasCovidReport? moment(matchCovidReport?.valid_until).isBefore(today) : null
    let test_expired = test_validity < 0 ? moment(matchCovidReport?.valid_until) : false
   
    
    if (employee?.vaccineTimeline?.first_dose_date && !employee?.vaccineTimeline?.second_dose_date) {
      vaccine_seconddose = 'TBA'
    }
    if (!employee?.vaccineTimeline?.first_dose_date && !employee?.vaccineTimeline?.second_dose_date) {
      vaccine_days = ''
    }
    if (employee?.vaccineTimeline?.first_dose_date && employee?.vaccineTimeline?.second_dose_date) {
      vaccine_days = 'N/A'
    }

    const age = employee.birth_date ? moment.utc().diff(moment.utc(employee.birth_date), 'years') : '-'
    return {
      ...employee,
      age,
      employee_id: employee.id,
      id: employee.reference_id || "-",
      name: employee.last_name + ", " + employee.first_name,
      company_id: employee.employee_id || "-",
      dependent_id: employee.employee_id || "-",
      role: employee.role || "-",
      department: employee.department || "-",
      business_unit: employee.business_unit || "-",
      work_nature: employee.work_type || "-",
      worker_type: employee.third_party_employer ? "Third-Party" : "Internal",
      status: employee.status,
      civil_status: employee.civil_status || "",
      gender: employee.gender || 'Unspecified',
      vaccine_days,
      vaccine_firstdose:vaccine_firstdose,
      vaccine_seconddose:vaccine_seconddose,
      covid_vaccination_eligibility: employee?.vaccineTimeline?.nextDoseType,
      test_status: hasCovidReport>=0 ? "TESTED":" UNTESTED",
      test_result: matchCovidReport?.result || '',
      test_duration: test_duration,
      test_validity: test_validity,
      test_still_valid:test_still_valid|| 'NA',
      test_expired: test_expired,
      test_type: matchCovidReport?.sub_type || '',
    };
  });
  return rowsFmt;
  
}
export { gridColumns, gridRows, risks };
