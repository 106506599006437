import {
	Box, debounce, LinearProgress, Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import DataGridProvider, {
	actions as gridActions,
	DataGridContext
} from "@zennya/web-component-library/src/components/DataGrid/DataGridContext";
import { DataGrid2 as DataGrid } from "@zennya/web-component-library/src/components/DataGrid2/DataGrid2";
import { useConfirm } from "material-ui-confirm";
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import corporateCohortsService from "../services/corporate-cohorts.service";
import { useState } from "../utils/stateref";
import { cohortsData } from "./cohortsData";


const useStyles = makeStyles(theme => ({
    linkColor: {
        color: theme.palette.link.main
    }
}));

const INITIAL_PARAMS = {
    page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
	page_offset: 0,
}

function CohortsList(props) {
    const confirm = useConfirm()
    const handleFocusedCohort = props.handleFocusedCohort;
    const [state, dispatch] = useContext(DataGridContext);
    const [pageState, setPage, page] = useState(1);
	const [loadRows, setLoadRows, loadRowsRef] = useState([]);
	
	const [params, setParams, paramsRef] = useState(INITIAL_PARAMS);
    const [reset, setReset, resetRef] = useState();

	const [totalCount, setTotalCount] = useState(0);
    const history = useHistory();

    const tableRef = useRef(null);

    const resetScroll = useCallback(() => {
        if (tableRef.current) {
            tableRef.current.scrollTop = 0;
        }
    }, []);
	
    const loadCohorts = useCallback(
        async () => {
            try {
                dispatch({ type: gridActions.SET_LOADING })
                if (resetRef.current){
                    resetScroll();
					setParams({...paramsRef.current, ...INITIAL_PARAMS})
				}
                const data = await cohortsData.getProcessedCohortsList(props.company, paramsRef.current)
				setTotalCount(data.count)
                let newList = data.rows.map(cohort => {
                    return {
                        id: cohort.id,
                        label: cohort.label,
                        description: cohort.description,
                        entry_count: cohort.entry_count,
                        date_created: cohort.date_created,
                        last_updated: cohort.last_updated
                    }
                })
                let appendRows = [...newList]
				if (!resetRef.current){
					appendRows = [...loadRowsRef.current, ...newList]
				} else {
                    appendRows = [...newList]
                }
                setLoadRows(appendRows)
				dispatch({
					type: gridActions.LOAD_ROWS,
					payload: {
						rows: appendRows,
					},
				});

            } catch (e) {
                
            } finally {
                dispatch({ type: gridActions.SET_DONE_LOADING })
                setLogsLoader(false)

            }
        },
        [dispatch, loadRowsRef, paramsRef, props.company, resetRef, setLoadRows, resetScroll],
    );

    const loadMorePages = useCallback(
		() => {
			const newParams = reset||resetRef.current?
            {
                ...paramsRef.current,
                page_offset: 0,
            }:{
				...paramsRef.current,
				page_offset: ((page.current) * params.page_size),
			}
			setParams(newParams)
			loadCohorts()
			setPage(page.current + 1)
            
		},
		[loadCohorts, page, params.page_size, paramsRef, reset, resetRef, setPage, setParams],
	);

    useEffect(() => {
        // loadMorePages()
    }, []);

    
    const cohortClickCallback = useCallback((cohort) => {
        handleFocusedCohort(cohort)
    }, [handleFocusedCohort]);

    const cohortDeleteCallback = useCallback((cohort) => {
        confirm({
            description: 'Are you sure you want to delete this cohort?',
            cancellationButtonProps: { autoFocus: true },
        })
        .then(async() => {
            const data = await corporateCohortsService.deleteCohort(cohort.id)
			setReset(true)
            await loadCohorts()
        })
        .catch(() => {});
    }, [confirm, loadCohorts]);

    const cohortDownloadCallback = useCallback(async (cohort) => {
        const response = await corporateCohortsService.downloadCohortEntries(props.company, cohort.id)
        
            var downloadFile = `Cohorts${cohort?.label}-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
            var file = new Blob([response.data], {
                type: response.headers['content-type'] || 'application/octet-stream',
            });
            var saving = document.createElement('a');
            saving.href = URL.createObjectURL(file);
            saving.download = (
                downloadFile
            );
            saving.click();
        
    }, [props.company]);

	const bounceFilter = useCallback(
		debounce((term)=>{
			setParams({
				...INITIAL_PARAMS,
				search: term,
			})
			setReset(true)
			loadCohorts()
		}, 250),
		[],
	);

    const bounceOrder = useCallback(
		debounce((sort_by, sort_order)=>{
			setParams({
				...INITIAL_PARAMS,
				sort_by,
                sort_order: sort_order?.toLowerCase(),
			})
			setReset(true)
			loadCohorts()
		}, 250),
		[],
	);

	useEffect(() => {
		bounceFilter(state.filterColumn?.search)
	}, [state.filterColumn]);

    useEffect(() => {
        const cohortColumns = cohortsData.getColumns(cohortClickCallback, cohortDeleteCallback, cohortDownloadCallback)
		dispatch({
			type: gridActions.LOAD_COLUMNS,
			payload: {
				columns: cohortColumns
			},
		});
	}, [cohortClickCallback, cohortDeleteCallback, cohortDownloadCallback, dispatch]);

    const [ isLoadingLogs , setLogsLoader ] = useState(false)
    // useEffect(() => {
	// 	setReset(true)
    //     loadCohorts()
    // }, []);

    const handleSort = useCallback(
        async(col, dir) => {
            console.log('cohortsList.js (175) # col, dir', col, dir);
            setReset(true)
            bounceOrder(col, dir)
        },
        [bounceOrder],
    );

    const dgridMemo = useMemo(() => {
		setReset(true)
        return (
            <DataGrid
                ref={tableRef}
				hasDateRangeFilter={false}
				filterable
				deferLoading
                onSort={(col, dir) => handleSort(col, dir)}
				onLoadMore={() => {
                    setReset(false)
                    loadMorePages({reset:false})
				}}
				showSelector
				gridProps={{
					rowKeyGetter: row => {
						return row.id;
					},
					emptyRowsRenderer: () => (
						<Box textAlign="center" p="1rem" color="grey">
							No matching records found
						</Box>
					),
				}}
			/>
            
        )
    }, [handleSort, loadMorePages, setReset]);

    return (
        <div style={{ height: '75vh', display: "flex", flexDirection: "column" }}>
            {isLoadingLogs && <LinearProgress style={{ width: '100%' }} />}
            {dgridMemo}
        </div>
    )
}

export default function WrapCohortsList(props) {
    return (
        <DataGridProvider>
            <CohortsList {...props} />
        </DataGridProvider>
    );
}