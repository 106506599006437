import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import HealthRiskMetrics from '../../healthRisk/healthRiskMetrics';
import voca from 'voca';
import chroma from 'chroma-js'
import { EmployeeMetrics } from '../employeeMetrics';
import { PEOPLE_TYPE } from '../employeeList';

function generatePalette (count) {
  return chroma.scale([
    '#9bbbdc',
  // '#cce4ea',
  '#74bacd',
  '#85cbc8',
  // '#7f8aac',
  '#eebe6a',
  '#f5a97e',
  '#f59b94',
  ])
  .mode('lab')
  .colors(count)
}
  
const useStyles = makeStyles((theme) => {
  return {
    metric: {
      paddingTop: theme.spacing(2),
    }
  }
})
const Metrics = ({ metrics = [], company, peopleType=PEOPLE_TYPE.WORKERS}) => {

  const classes = useStyles()
  return (<>
    <Grid container className={classes.metric} spacing={2}>
      {Object.entries(metrics).map(([key, values]) => {
        return (
          <Grid item key={key} xs={4}>
            <HealthRiskMetrics
              renderKey={metrics}
              title={key}
              labels={values.labels}
              data={values.keys}
              backgroundColors={generatePalette(values?.keys?.length??0)}
            />
          </Grid>
        )
      })}
      <EmployeeMetrics peopleType={peopleType} partnerId={company} isHealth/>
    </Grid>
  </>
  )
}

export { Metrics };
