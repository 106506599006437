import isEmpty from "lodash.isempty";

export function isVaccineCompleted(vaccines, masterList) {
  if (isEmpty(masterList) || isEmpty(vaccines)) {
    return {
      completedSome: false,
      completedAll: false,
    };
  }
  let vaccineCounts = {};
  let vaccineDoses = {};

  vaccines.reduce((acc, curr) => {
    if (!vaccineCounts[curr.vaccine]) {
      vaccineCounts[curr.vaccine] = 0;
    }
    vaccineCounts[curr.vaccine]++;
    return acc;
  }, vaccineCounts);

  vaccineCounts = Object.keys(vaccineCounts).reduce((acc, curr) => {
    const masterVaccine = masterList.find((vaccine) => vaccine.name === curr);
    acc[curr] = vaccineCounts[curr] >= masterVaccine.doses;
    return acc;
  }, vaccineDoses);

  const completedSome = Object.keys(vaccineDoses).some(
    (vaccine) => vaccineDoses[vaccine]
  );

  const completedAll = Object.keys(vaccineDoses).some(
    (vaccine) => vaccineDoses[vaccine]
  );

  return { completedSome, completedAll };
}

export function isVaccinated(vaccines) {
  return vaccines.length > 0;
}

export function generateFirstSecondDose(vaccines, masterList, completion, isImmunoCompromised = false) {
  if (isEmpty(vaccines)) {
    return {
      nextVaccine: null,
      first_dose_date: null,
      second_dose_date: null,
      nextDoseFrequency: 0,
      nextDoseType: "1st Dose",
    };
  }

  if (vaccines.length <= 1) {
    const nextDose = masterList.find(
      (vaccine) => vaccine.name === vaccines[0].vaccine
    );
    if (nextDose.doses === 1) {
      return {
        nextVaccine: vaccines[0]?.vaccine,
        first_dose_date: vaccines[0]?.doseDate,
        second_dose_date: null,
        nextDoseFrequency: nextDose.booster_frequency,
        nextDoseType: "1st Booster",
      };
    }
    return {
      nextVaccine: vaccines[0]?.vaccine,
      first_dose_date: vaccines[0]?.doseDate,
      second_dose_date: null,
      nextDoseFrequency: nextDose.frequency,
      nextDoseType: "2nd Dose",
    };
  }
  if (vaccines.length >= 2) {
    if(!completion.completedSome) {
      const nextDose = masterList.find(
        (vaccine) => vaccine.name === vaccines[0].vaccine
      );
      if (nextDose.doses === 1) {
        return {
          nextVaccine: vaccines[0]?.vaccine,
          first_dose_date: vaccines[0]?.doseDate,
          second_dose_date: null,
          nextDoseFrequency: nextDose.booster_frequency,
          nextDoseType: `${getOrdinal(nextDose.doses)} Booster`,
        };
      }
      return {
        nextVaccine: vaccines[0]?.vaccine,
        first_dose_date: vaccines[0]?.doseDate,
        second_dose_date: null,
        nextDoseFrequency: nextDose?.frequency,
        nextDoseType: "2nd Dose",
      };
    }
    const vaccBooster = masterList.find(
      (vaccine) => vaccine.name === vaccines[0].vaccine
    )
    return {
      nextVaccine: vaccines[0]?.vaccine,
      first_dose_date: vaccines[1]?.doseDate,
      second_dose_date: vaccines[0]?.doseDate,
      nextDoseFrequency: isImmunoCompromised ? vaccBooster?.booster_frequency_2nd_immuno_compromised : vaccBooster?.booster_frequency_2nd,
      nextDoseType: `${getOrdinal(vaccines.length - 1)} Booster`,
    };
  }
}

export function getOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
