/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import './extHealthPass.css';
import clsx from 'clsx';
import { Divider } from '@material-ui/core';
import corporateHealthService from './../services/corporate-health.service';
import {
    Grid,
    Typography,
    Snackbar,
    IconButton,
    Slide,
    useMediaQuery,
} from '@material-ui/core';
import {
    Alert,
} from "@material-ui/lab";
import {
    Close as CloseIcon
} from  "@material-ui/icons"
import {
    useTheme
} from "@material-ui/core/styles"
import QRCode from 'qrcode';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import avatarPlaceholderIcon from './../assets/svg/icon_avatar_placeholder.svg';
import blurredQrPlaceholderIcon from './../assets/images/Blurred.png';
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import * as QueryString from "query-string"

const useStyles = makeStyles(theme => ({
    sliderPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}));


function ExternalHealthPass(props) {
    const classes = useStyles();
    const { employee } = props;
    const { isMobileMode } = props;
    const [ loading, setLoading ] = useState(false);
    const [ noTempRecorded, setNoTempRecorded ] = useState(false)
    const [ noCovidAssessmentRecorded, setNoCovidAssessmentRecorded ] = useState(false);
    const [ qrCode, setQrCode ]  = useState('');
    const [ qrColor, setQrColor ] = useState('#000000');
    const [ tempColor, setTempColor ] = useState('');
    const [ ongoingQuarantine, setOngoingQuarantine ] = useState(false);
    const [ mobileMode, setMobileMode ] = useState(false);

    const marks = [
        {
          value: 0,
          label: '0 days',
        },
        {
          value: 14,
          label: '14 days',
        },
      ];
      

    const QuarantineSlider = withStyles({
        root: {
            color: '#65C3A9',
            height: 3,
            padding: '13px 0',
        },
        thumb: {
            height: 27,
            width: 27,
            backgroundColor: '#fff',
            border: '1px solid #EA857D',
            marginTop: -12,
            marginLeft: -13,
            boxShadow: '#ebebeb 0 2px 2px',
            '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
            },
            '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            // backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
            },
        },
        active: {},
        track: {
            height: 3,
        },
        rail: {
            color: '#d8d8d8',
            opacity: 1,
            height: 3,
        },
        markLabel: {
            fontWeight: 700
        },
        mark: {
            margin: 0
        }
    })(Slider);

    function SliderThumbComponent(props) {
        return (
          <span {...props}>    
                <span>
                    <FontAwesomeIcon icon={faHouseUser} style={{ color: '#EA857D'}} />
                </span>
          </span>
        );
      }

    function assignLogic() {
        var inQuarantine = employee.quarantine && employee.quarantine.status && employee.quarantine.status.status === 'ONGOING';
        var temp = employee.temperature_assessment ? employee.temperature_assessment.reading : null;

        generateQr(inQuarantine || temp != null ? employee.risk.color : qrColor);

        if (inQuarantine) {
            setOngoingQuarantine(true);
        } else {
            setOngoingQuarantine(false);
        }

        setNoTempRecorded(temp == null);
        if (temp) {
            if(temp <= 37.5) {
                setTempColor('green');
            } else if(temp >= 37.6 && temp <= 39.4) {
                setTempColor('orange');
            } else if (temp > 39.4) {
                setTempColor('red');
            }
        }

        setNoCovidAssessmentRecorded(!employee.covid_assessment || !employee.covid_assessment.is_valid);
    }

    function generateQr(color) {
        var opts = {
            errorCorrectionLevel: 'H',
            type: 'image/jpeg',
            quality: 0.5,
            margin: 1,
            color: {
              dark: color,
              light: '#FFFFFF'
            }
        };

        QRCode.toDataURL(employee.employee.hash_id || '', opts).then(
            url => {
                setQrCode(url);
            }, error => {

            }
        );
    }

    function getCovid19Assessment() {
        if (noCovidAssessmentRecorded) {
            return undefined;
        } else if (employee.covid_assessment) {
            if (!employee.covid_assessment.passed) {
                return (
                    <Typography className="normal-text" style={{ fontWeight: 700 }}>
                        <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#E58882', paddingRight: 5}} size="lg"/>  Not Cleared
                    </Typography>
                )
            } else {
                return (
                    <Typography className="normal-text" style={{ fontWeight: 700 }}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#65C3A9', paddingRight: 5}} size="lg"/>  Passed
                    </Typography>
                )
            }
        }
    }

    const assignColor = () => {
        var temp = employee.temperature_assessment ? employee.temperature_assessment.reading : null;
        if(temp <= 37.5) {
            return 'green';
        } else if(temp >= 37.6 && temp <= 39.4) {
           return 'orange';
        } else if (temp > 39.4) {
           return 'red';
        }
        return '';
    }

    function getTemperatureRecorded() {
        return (
            <React.Fragment>
                <div>
                {/* style={{ borderTop: '1px solid #dce0dc', borderBottom: '1px solid #dce0dc', width: '100%'}}> */}
                    <Typography className="content-header content-header-text">
                        Body Temperature
                    </Typography>
                    <Grid container direction="row" alignItems="flex-start" justify="flex-start">
                        { noTempRecorded ? 
                        <Grid item xs={12}>
                            <span className="misc-desc date-text" style={{textAlign: 'left'}}>
                                No temperature recorded today.
                            </span>
                        </Grid>
                         : undefined }
                        <Grid item xs={7}>
                                { !noTempRecorded ? <div className="temperature-bar">
                                <div className="bar bar-low">
                                    <span className="bar-text status-text-main">
                                        NORMAL
                                    </span>
                                </div>
                                <div className="bar bar-med">
                                    <span className="bar-text status-text-main">
                                        MODERATE
                                    </span>
                                </div>
                                <div className="bar bar-high">
                                    <span className="bar-text status-text-main">
                                        HIGH
                                    </span>
                                </div>
                            </div>: undefined } <br />
                            {/* { !noTempRecorded ? <div className="dot">
                                    YOU
                                </div>: undefined} */}
                            { !noTempRecorded ? <div className="temperature-bar temperature-signage">
                                <div className="bar bar-low">
                                   <Typography className="temp-signage-text" style={{ textAlign: 'right'}}>
                                       37.5&#xb0;C
                                   </Typography>
                                </div>
                                <div className="bar bar-med">
                                   
                                </div>
                                <div className="bar bar-high">
                                    <Typography className="temp-signage-text" style={{ textAlign: 'left'}}>
                                       39.4&#xb0;C
                                   </Typography>
                                </div>
                            </div>: undefined }
                        </Grid>
                        { employee.temperature_assessment.reading && !noTempRecorded ? <Grid item xs={5} align="left">
                            <Typography className="temp-text" style={{ textAlign: 'center', padding: 10 }}>
                                { employee.temperature_assessment.reading } 
                                <span className="normal-text">&#xb0;C</span>
                            </Typography>
                            <Typography className="normal-text" style={{ textAlign: 'center', padding: 10 }}>
                                { assignColor() === 'green' ? 'NORMAL \n TEMPERATURE' : ''}
                                { assignColor() === 'orange' ? 'MODERATE \n FEVER': ''}
                                { assignColor() === 'red' ? 'HIGH \n FEVER' : ''}
                            </Typography>
                        </Grid> : undefined }
                    </Grid>
                    { !noTempRecorded ? <span className="misc-desc date-text" style={{textAlign: 'left'}}>
                        { employee.temperature_assessment.reading ?
                            moment(employee.temperature_assessment.date_taken).format('MM/DD/YY hh:mm A')
                            : undefined
                        }
                    </span> : undefined }
                </div>
            </React.Fragment>
        )
    }


    useMemo(() => {
        var inQuarantine = employee.quarantine && employee.quarantine.status && employee.quarantine.status.status === 'ONGOING';
        var temp = employee.temperature_assessment ? employee.temperature_assessment.reading : null;

        generateQr(inQuarantine || temp != null ? employee.risk.color : qrColor);

        if (inQuarantine) {
            setOngoingQuarantine(true);
        } else {
            setOngoingQuarantine(false);
        }

        setNoTempRecorded(temp == null);
        if (temp) {
            if(temp <= 37.5) {
                setTempColor('green');
            } else if(temp >= 37.6 && temp <= 39.4) {
                setTempColor('orange');
            } else if (temp > 39.4) {
                setTempColor('red');
            }
        }

        setNoCovidAssessmentRecorded(!employee.covid_assessment || !employee.covid_assessment.is_valid);

    }, [noTempRecorded, noCovidAssessmentRecorded, ongoingQuarantine, employee]);

    return(
        <Grid id="main-container" container direction="row">
            <Grid className="main-content" item xs={12}>
                <Grid container direction="column" justify="center" alignItems="center">
                    {
                        !isMobileMode
                        && (
                        <a className="icon-header-content" href="#" target="_blank">
                            {
                                employee.company
                                && employee.company.logo_url
                                && (
                                    <img className="icon-header-content" src={employee.company.logo_url} alt="logo" />
                                )
                            }
                        </a>
                        )
                    }
                </Grid>

                <Grid container direction="column">
                    {
                        !isMobileMode
                        && (
                        <Grid container direction="column" justify="center" alignItems="center" style={{ marginBottom: 10}}>
                            <div className="avatar-content">
                                <div className="avatar-content2">
                                    { employee.employee && employee.employee.photo_url ?
                                        <img src={employee.employee.photo_url} alt="Client"/> :
                                        <img src={avatarPlaceholderIcon} alt="Client Placeholder"/>
                                    }
                                </div>
                            </div>
                        </Grid>
                        )
                    }
                    {
                        !isMobileMode
                        && (
                        <Grid container direction="column" justify="center" alignItems="center">
                            {/* <div className="avatar-name-content"> */}
                                {
                                    (
                                        <Typography className="name-content">
                                            { employee.employee && employee.employee.display_name }
                                        </Typography>
                                    )
                                }
                                {
                                    employee.roles
                                    && (
                                        <Typography className="position-content">
                                            { employee.roles }
                                        </Typography>
                                    )
                                }
                                {
                                    employee.employee && employee.employee.card_number
                                    && (
                                        <Typography className="position-content" style={{ fontWeight: 'bolder'}}>
                                            ID Number: { employee.employee.card_number}
                                        </Typography>
                                    )
                                }
                            {/* </div> */}
                        </Grid>
                        )
                    }
                    {
                        !isMobileMode
                        && (
                            <Grid container direction="row" justify="center" alignItems="center">
                                <img className="barcode-content" src={employee.employee && employee.employee.hash_id ? qrCode : blurredQrPlaceholderIcon} alt="Client QR"/>
                            </Grid>
                        )
                    }
                    <Grid container direction="row" justify="center" alignItems="center">
                        { ongoingQuarantine || (!noCovidAssessmentRecorded && !noTempRecorded && !ongoingQuarantine) ? <Typography component="span" variant="body1" className="main-message">
                            { employee.message }
                        </Typography> : undefined }
                    </Grid>
                    {   employee && employee.risk && employee.risk.color && !noTempRecorded && !noCovidAssessmentRecorded &&
                        <Grid container direction="row" justify="center" alignItems="center" style={{ marginBottom: 15}}>
                             <Typography className="normal-text" style={{ textAlign: 'left', paddingLeft: 10}}>
                               <FontAwesomeIcon icon={faExclamationCircle} style={{ color: employee.risk.color}} size="lg"/>
                               <span style={{ textTransform: 'capitalize', paddingLeft: 5, color: '#4A4A4A', fontWeight: 700}}>
                               { employee.risk.level.toLowerCase() } Risk
                                </span>
                            </Typography>
                        </Grid>
                    }
                    {
                        ongoingQuarantine &&
                        <Grid container direction="row" justify="center" alignItems="center" className={classes.sliderPadding}>
                            { employee && employee.quarantine && employee.quarantine.end_date ? <QuarantineSlider
                                valueLabelDisplay="auto" aria-label="quarantine slider"
                                ThumbComponent={SliderThumbComponent}
                                    // getAriaValueText={(value) => `${value}`}
                                    valueLabelDisplay="auto"
                                    value={moment(employee.quarantine.end_date).diff(moment(), 'days')}
                                    max={14}
                                    min={0}
                                    marks={marks}
                            /> : undefined }
                        </Grid>
                    }
                    {
                        employee.employee && !employee.employee.id
                        && (
                            <Grid item xs={12}>
                                <Typography className="misc-desc employee-status-text covid-status-text">
                                    Person is not registered in the system.
                                </Typography>
                            </Grid>
                        )
                    }
                </Grid>

                {
                    employee.employee && employee.employee.id
                    && (
                        <Grid className="content-padding" container direction="column" justify="center" alignItems="flex-start">
                            { getTemperatureRecorded() }
                        </Grid>
                    )
                }
                {
                    employee.employee && employee.employee.id
                    && (
                        <Grid className="content-padding" container direction="row" justify="center" alignItems="center">
                            <Grid item xs={7}>
                                <Typography className="content-header content-header-text">
                                    COVID Daily Screen
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <div className="covid-status-bar">
                                    { getCovid19Assessment() }
                                </div>
                            </Grid>
                            {
                                noCovidAssessmentRecorded ? 
                                <Grid item xs={12}>
                                    <span className="misc-desc text-left">
                                        No COVID-19 assessment recorded today.
                                    </span>
                                </Grid> : undefined
                            }
                            <Grid item xs={12} align="left" className="misc-desc text-left date-text" style={{ paddingLeft: 0}}>
                            {
                                employee.covid_assessment
                                && employee.covid_assessment.date
                                && !noCovidAssessmentRecorded
                                && (
                                    moment(employee.covid_assessment.date).format('MM/DD/YY hh:mm A')
                                )
                            }
                            </Grid>
                        </Grid>
                    )
                }
                {
                    employee.employee.id
                    && (
                        <Grid className="content-padding" container direction="column" justify="center" alignItems="flex-start">
                            <Typography className="content-header content-header-text">
                                Flu Vaccination
                            </Typography>
                            <Typography className="misc-desc text-left">
                                {employee?.flu_vaccine?.brand}<br/>
                                {moment(employee?.flu_vaccine?.dose_date).format('DD MMMM YYYY')}
                            </Typography>
                        </Grid>
                    )
                }
                {
                    employee.employee.id && employee.company
                    && (
                        <Grid className="content-padding" container direction="column" justify="center" alignItems="flex-start">
                            <Typography className="content-header content-header-text">
                                About { employee.company.label }
                            </Typography>
                            <Typography className="misc-desc text-left">
                                {employee.company.description || '-' }
                            </Typography>
                            <br/>
                            <br/>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default function CorporateHealthPass(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [ employee, setEmployee ] = useState({});
    const [ connectionNotification, setConnectionNotification ] =  useState(false)
    const [ closeNotification, setCloseNotification ] = useState(false)
    const [ pollingCounter, setPollingCounter ] = useState(0)
    const [ ongoingQuarantine, setOngoingQuarantine ] = useState(false);

    const pollingTimeout = 1000 * 3
    const employeeNotFound = {
        employee: {
            id: null,
            display_name: 'Person not found'
        },
        company: {
            description: '',
            label: '',
            logo_url: ''
        },
        covid_assessment: null,
        temperature: {
            entries: []
        },
        risk: {
            level: ''
        }
    }

    const params = QueryString.parse(props.location.search);
    var isMobileMode = params.m == "1"

    useEffect(() => {
        corporateHealthService.getHealthPass(props.match.params.hashId, 10000)
            .then(response => {
                setEmployee(response);

                if (connectionNotification) {
                    setConnectionNotification(false)
                }

                if (closeNotification) {
                    setCloseNotification(false)
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.code == 'CORP_HEALTH_CHECK_EMPLOYEE_NOT_FOUND') {
                    setEmployee(employeeNotFound);
                }

                if (!err.response && !closeNotification && !connectionNotification && !isMobileMode) {
                    setConnectionNotification(true)
                }
            })
            .finally(()  => {
                setTimeout(() => {
                    setPollingCounter(current => current + 1)
                }, pollingTimeout)
            })
        ;
      }, [pollingCounter]);

    return (
        <React.Fragment>
            <Snackbar
                open={connectionNotification}
                TransitionComponent={Slide}
                style={{ width: isMobile ? "100%" : "" }}
                anchorOrigin={{
                    horizontal: isMobile ? "center" : "right",
                    vertical: isMobile ? "bottom" : "top"
                }}
            >
                <Alert
                    variant={"filled"}
                    severity="error"
                    style={{ width: isMobile ? "100%" : "" }}
                    action={
                        <IconButton
                            size={"small"}
                            aria-label="close"
                            color={"inherit"}
                            onClick={() => {
                                setCloseNotification(true)
                                setConnectionNotification(false)
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    No internet connection.
                </Alert>
            </Snackbar>
            { employee && employee.employee && <ExternalHealthPass employee={employee} isMobileMode={isMobileMode} /> }
        </React.Fragment>
    )
}
