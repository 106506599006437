import {
  Button,
  ButtonGroup,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  withStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import corporateEventsService from "../..//services/corporate-events.service";
import corporateCohortsService from "../../services/corporate-cohorts.service";
import EmployeeGrid from "../../employees/grid";

import { EventHeaderDetail } from "./EventHeaderDetail";
import { cohortsEntriesData, EVENT_INVITE_STATUS, prepEntryLink, VACCINE_STATUS } from "../cohortsData";
import { useConfirm } from "material-ui-confirm";
import corporateEmployeeService from "../../services/corporate-employee.service";
import corporateHealthService from "../../services/corporate-health.service";
import { useHistory } from "react-router-dom";
import { EventMetrics } from "./eventMetrics";
import { Sort } from "@material-ui/icons";
import moment from "moment";
import { ResourceAgenda } from "./ResourceAgenda";
import { isEmpty } from "voca";

const styles = (theme) => ({
  cancel: {
    color: theme.palette.error.main,
  },
  cohortContainer: {
    paddingTop: theme.spacing(1),
  },
  root: {
    textAlign: "start",
    padding: theme.spacing(4),
    flexGrow: 1,
    maxWidth: "1440px",
  },
  linkColor: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
});

function EventDetail({
  company,
  match,
  openCohort,
  classes,
  onClose,
  onCancelEvent,
}) {
  const [rowVer, setRowVer] = useState(0);
  const confirm = useConfirm();

  const [eventId, setEventId] = useState(match?.params?.eventId);
  const [event, setEvent] = useState();

  const [baseConsolidatedCohortData, setBaseConsolidatedCohortData] = useState([]);
  const [consolidatedCohortData, setConsolidatedCohortData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [isAllrowsSelected, setIsAllrowsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function fetchAllCohorts() {
      if (!event) return;
      const allCohorts = (event?.services ?? [])
        .map((service) => {
          return service.cohorts.map((cohort) => {
            return cohort.id;
          });
        })
        .flat();
      const cohortData = await Promise.all(
        allCohorts.map(async (cohortId) => {
          const fetchCohorts =
            await corporateEventsService.getEventCohortEntries(
              cohortId,
              event.id
            );
          return [
            ...fetchCohorts.list.map((entry) => {
              let vaccine_days = moment().diff(
                moment(entry?.vaccine?.first_dose_date),
                "days"
              );
              let vaccine_firstdose = entry?.vaccine?.first_dose_date
                ? `${entry?.vaccine?.brand} - ${moment(
                    entry?.vaccine?.first_dose_date
                  ).format("LL")}`
                : null;
              let vaccine_seconddose = entry?.vaccine?.second_dose_date
                ? `${entry?.vaccine?.brand} - ${moment(
                    entry?.vaccine?.second_dose_date
                  ).format("LL")}`
                : null;
              if (
                entry?.vaccine?.first_dose_date &&
                !entry?.vaccine?.second_dose_date
              ) {
                vaccine_seconddose = "TBA";
              }
              if (
                !entry?.vaccine?.first_dose_date &&
                !entry?.vaccine?.second_dose_date
              ) {
                vaccine_days = "";
              }
              if (
                entry?.vaccine?.first_dose_date &&
                entry?.vaccine?.second_dose_date
              ) {
                vaccine_days = "N/A";
              }
              const employee = {
                ...entry?.employee,
                full_name:
                  entry?.full_name ??
                  entry?.employee?.first_name +
                    " " +
                    entry?.employee?.last_name,
              };
              return {
                ...entry,
                age: entry.birthdate
                  ? moment.utc().diff(moment.utc(entry.birthdate), "years")
                  : "-",
                id: entry.id,
                company_id: entry.company_id ? entry.company_id : "-",
                name: entry.full_name,
                role: entry.role ? entry.role : "-",
                work_type: null, // no discernable data point for this
                work_nature: entry.work_nature ? entry.work_nature : "-",
                vaccine_days,
                vaccine_firstdose,
                vaccine_seconddose,
              };
            }),
          ];
        })
      );

      const deDuped = cohortData
        .flat()
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
      setConsolidatedCohortData(deDuped);
      setBaseConsolidatedCohortData(deDuped)
      setLoading(false);
    }
    fetchAllCohorts();
  }, [classes, event, history]);

  const onAllRowsSelectionChange = useCallback(
    (rowset) => {
      setIsAllrowsSelected(rowset);
      const empls = new Set();
      if (rowset) {
        consolidatedCohortData.forEach((element) => {
          empls.add(element.id);
        });
      }
      setSelectedRows(empls);
    },
    [consolidatedCohortData]
  );

  useEffect(() => {
    let cols = cohortsEntriesData.getColumns({
      // allRowsSelected: false,
      // onAllRowsSelectionChange: () => {},
      allRowsSelected: isAllrowsSelected,
      onAllRowsSelectionChange,
      isEvent: true,
    });
    if (event?.status !== "PENDING") {
      cols.shift();
    }
    setColumns(cols);
  }, [isAllrowsSelected, onAllRowsSelectionChange, event?.status]);

  const fetchEventDetails = useCallback(async () => {
    setLoading(true);
    const result = await corporateEventsService.getEventDetails(eventId);
    setEvent(result);
  }, [eventId]);

  useEffect(() => {
    fetchEventDetails();
  }, [eventId, fetchEventDetails]);

  async function cancelEvent() {
    await corporateEventsService.cancelEvent(event.id);
    history.go(0);
  }

  // const sendInvitesAll = async () => {
  //   const unlinkedrefs = await corporateHealthService.getPartnerEmployeesAndReferences(props.company, {linked:false})
  //   console.log("📢[employeeList.js:306]:", unlinkedrefs);
  // }
  const sendEventInvites = async (selRowIds) => {
    //todo: send invites to event (not yet implemented)
    const result = await corporateEventsService.sendEventInvites(
      event.id,
      Array.from(selRowIds)
    );
  };

  async function downloadCSV() {
    setDownloadLoading(true);
    corporateEventsService
      .downloadEventParticipants(event.id)
      .then((response) => {
        var downloadFile = `CorporateEventInvitesStatuses${
          event.id
        }_${new Date().getTime()}.csv`;
        var file = new Blob([response], {
          type: "text.csv",
        });
        var saving = document.createElement("a");
        saving.href = URL.createObjectURL(file);
        saving.download = downloadFile;
        saving.click();
        setDownloadLoading(false);
      });
  }

  const renderRightAccessory = useCallback(() => {
    if (selectedRows.length > 0) {
      return (
        <Button
          variant="contained"
          onClick={() => {
            confirm({
              title: "Send invites",
              description: "Send email invites to selected employees?",
              cancellationButtonProps: { autoFocus: true },
            })
              .then(() => sendEventInvites(selectedRows))
              .catch(() => {});
          }}
          color="primary"
        >
          Invite to event ({selectedRows.length})
        </Button>
      );
    }
    return <b>Participants</b>;
  }, [confirm, selectedRows]);

  const localSort = useCallback(
    (sort) => {
      const sortedData = consolidatedCohortData.sort((a, b) => {
        let sortRef = 0;
        const aName = a?.employee?.last_name + " " + a?.employee?.first_name;
        const bName = b?.employee?.last_name + " " + b?.employee?.first_name;
        switch (sort?.column) {
          case "vaccine":
            sortRef = (a?.vaccine?.brand ?? "")
              .toString()
              .localeCompare((b?.vaccine?.brand ?? "").toString());
            break;
          case "name":
            sortRef = (aName ?? "")
              .toString()
              .localeCompare((bName ?? "").toString());
            break;
          default:
            sortRef = (a[sort?.column] ?? "")
              .toString()
              .localeCompare((b[sort?.column] ?? "").toString());
        }
        return (sort?.value === "ASC" ? 1 : -1) * sortRef;
      });
      setConsolidatedCohortData(sortedData);
      // setRowVer(rowVer + 1);
    },
    [consolidatedCohortData]
  );

  
  const localFilter = useCallback(
    (filters) => {
      const searchKeys = Object.keys(filters);
      const filteredParticipants = searchKeys.reduce((data, searchKey) => {
        const match = data.filter((participant) => {
          let matches = true
          switch (searchKey) {
            case 'vaccination_status':
              if(filters[searchKey] === '') return matches
              if (participant.vaccine?.first_dose_date!==null && participant.vaccine?.second_dose_date !== null) {
                matches = filters[searchKey] === VACCINE_STATUS.VACCINATED
              } else if (participant.vaccine?.first_dose_date===null && participant.vaccine?.second_dose_date === null){
                matches = filters[searchKey] === VACCINE_STATUS.UNVACCINATED
              } else {
                matches = filters[searchKey] === VACCINE_STATUS.FIRST_DOSE
              }
              return matches;
            case 'invite':
              if(filters[searchKey] === '') return matches
              switch ((participant?.invite?.status??"").toUpperCase()) {
                case EVENT_INVITE_STATUS.SENT.toUpperCase():
                case EVENT_INVITE_STATUS.PENDING.toUpperCase():
                  matches = filters[searchKey] === EVENT_INVITE_STATUS.SENT
                  break;
                case EVENT_INVITE_STATUS.ACCEPTED.toUpperCase():
                  matches = filters[searchKey] === EVENT_INVITE_STATUS.ACCEPTED
                  break;
                case EVENT_INVITE_STATUS.DECLINED.toUpperCase():
                  matches = filters[searchKey] === EVENT_INVITE_STATUS.DECLINED
                  break;
                case '':
                  matches = filters[searchKey] === EVENT_INVITE_STATUS.UNSENT
                  break
                default:
                  matches = true
              }
              return matches
            case 'search':
              return participant?.full_name.toLowerCase().includes(filters[searchKey].toLowerCase())
            default:
              return true
          }
        })
        return match
      }, baseConsolidatedCohortData)
      // setConsolidatedCohortData(isEmpty(searchKeys) ? baseConsolidatedCohortData: filteredParticipants)
      setConsolidatedCohortData(filteredParticipants)
    },
    [baseConsolidatedCohortData],
  );

  if (!event) {
    return <LinearProgress />;
  }
  return (
    <div className={classes.root}>
      <CardHeader
        title={event?.label}
        action={
          <ButtonGroup>
            {event?.status === "REQUESTED" &&
              <Button
                className={classes.cancel}
                onClick={() => cancelEvent()}
                color="secondary"
              >
                Cancel event
              </Button>
            }
            <Button
              variant="contained"
              disabled={downloadLoading}
              color="primary"
              onClick={() => downloadCSV()}
            >
              Download Invites CSV
            </Button>
          </ButtonGroup>
        }
      />
      <CardContent component={Grid} container>
        <Grid item xs={12} md={12}>
          <EventHeaderDetail
            event={event}
            company={company}
            reloadEvent={() => fetchEventDetails()}
          />
        </Grid>
      </CardContent>
      <CardContent>
        <EventMetrics
          consolidatedCohortData={baseConsolidatedCohortData}
          eventId={eventId}
        />
        {/* <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <PieMetric metric={Metrics.INVITED} title="Invite Status" event={event} consolidatedCohortData={consolidatedCohortData} colors={['#7b8bb0', '#C2C2C2']}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PieMetric metric={Metrics.GENDER} title="Gender" event={event} consolidatedCohortData={consolidatedCohortData} colors={['#6A99CA', '#EA857D', '#EEBE6A']}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PieMetric metric={Metrics.AGE} title="Worker Age Range" event={event} consolidatedCohortData={consolidatedCohortData} colors={['#6A99CA', '#EA857D', '#EEBE6A']}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PieMetric metric={Metrics.COMORBIDITY} title="Workers with Health Conditions" event={event} consolidatedCohortData={consolidatedCohortData} colors={['#6A99CA', '#EA857D', '#EEBE6A']}/>
          </Grid> 
        </Grid> */}
      </CardContent>
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <EmployeeGrid
          compact
          loading={loading}
          columns={columns}
          disablePagination
          handleSort={(column, value) => {
            localSort(column, value);
          }}
          handleFilterChange={(filters) => {
            localFilter(filters);
          }}
          
          // company={props.company}
          // listCall={handleAfterSuccess}
          // onChangePage={onChangePage}
          // onChangeRowsPerPage={onChangeRowsPerPage}
          totalCount={baseConsolidatedCohortData.length}
          rows={consolidatedCohortData}
          // params={params}
          // page={page}
          // count={pages.count}
          leftAccessory={() => {
            return renderRightAccessory();
          }}
          onSelectedRows={(selRows) => {
            setSelectedRows(selRows);
          }}
          selectedRows={selectedRows}
          filterable
          // disableSearch
          disableDateRange
          rowVer={rowVer}
          style={{}}
        />
      </CardContent>
      <CardActions></CardActions>
    </div>
  );
}

export default withStyles(styles)(EventDetail);
