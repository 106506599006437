import React, { useState, useEffect } from "react"
import {
    IconButton,
    Menu,
    MenuItem,
    Tooltip
} from "@material-ui/core";
import {
    MoreVert as MoreVertIcon
} from "@material-ui/icons";
import SaveEmployeeDialog from "./SaveEmployeeDialog";
import DeleteEmployeeDialog from "./DeleteEmployeeDialog";
import CorporateEmployeeService from "../services/corporate-employee.service"

import AdminsOnlyComponent from "../components/AdminsOnlyComponent";
import AuthService from "../services/authentication.service"
import corporateHealthService from '../services/corporate-health.service';
import EmployeeAccessRestrictions from './../restrictions/employeeAccess';
import { GetLoggedInUser } from "../context/UserContext";

import logger, { snackbar } from "../services/logger.service";

export default function (props) {
    const user = GetLoggedInUser()
    const { company, restrictions, editHash } = props;
    const { disabled, employeeId, employee, updateEmployeeCallback } = props
    const [ anchorEl, setAnchorEl ] = useState(null)
    const [ editModal, setEditModal ] = useState(false)
    const [ deleteModal, setDeleteModal ] = useState(false)
    const [ isUpdatingEmployee, setIsUpdatingEmployee ] = useState(false)
    const [ editRestrictionsModal, setEditRestrictionsModal ] = useState(false);

    const { enqueueSnackbar } = snackbar()

    function saveEmployee(employee) {
        setIsUpdatingEmployee(true)

        const params = {
            ...employee, 
            first_name: employee.first_name,
            middle_name: employee.middle_name,
            last_name: employee.last_name,
            email: employee.email,
            gender: employee.gender,
            birth_date: employee.birthdate,
            mobile_number: employee.mobile_number,
            role: employee.role,
            worker_type: employee.worker_type,
            seniority: employee.seniority,
            department: employee.department,
            external_employer: employee.external_employer,
            business_unit: employee.business_unit,
            strategic_business_unit: employee.strategic_business_unit,
            entity: employee.entity
        }
        console.log("📢[MoreEmployeeProfileMenu.js:56]:", params, employee);
        CorporateEmployeeService.updateEmployee(employeeId, params)
            .then(() => {
                setEditModal(false)
                updateEmployeeCallback(val => val > 0 ? val - 1 : val + 1)

                logger.success(enqueueSnackbar, "Successfully updated employee")
            })
            .catch((error) => {
                logger.err(enqueueSnackbar, <div>Error ${error?.response?.status} ${error?.response?.data?.message?.en}<br/> ${error?.response?.data?.details}</div>)
            })
            .finally(() => {
                setIsUpdatingEmployee(false)
            })
    }

    return (
        <React.Fragment key={editHash}>
            <IconButton disabled={disabled} onClick={(event) => setAnchorEl(event.currentTarget)}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={
                    () => {
                        setAnchorEl(null)
                        setEditModal(true)
                    }
                }>
                    Edit Worker Details
                </MenuItem>
                <MenuItem onClick={
                    () => {
                        setAnchorEl(null)
                        setEditRestrictionsModal(true)
                    }
                }>
                    Manage Worker Access Restrictions
                </MenuItem>
                <MenuItem
                    onClick={
                        () => {
                            if (user.user.isAdmin) {
                                setAnchorEl(null)
                                setDeleteModal(true)
                            }
                        }
                    }
                    style={ !user.user.isAdmin ? { color: "grey" } : {} }
                >
                    <AdminsOnlyComponent>
                        <span>Deactivate Worker</span>
                    </AdminsOnlyComponent>
                </MenuItem>
            </Menu>

            { editRestrictionsModal && company && employee  && restrictions ? <EmployeeAccessRestrictions
                company={company}
                employee={employee}
                restrictions={restrictions}
                open={editRestrictionsModal}
                handleClose={() => { setEditRestrictionsModal(false)}}
            /> : undefined }

            <SaveEmployeeDialog
                partnerId={props?.company}
                editHash={editHash}
                modal={editModal}
                setModal={setEditModal}
                title={"Edit Worker Details"}
                subtitle={"Update the worker's details and press save"}
                actionText={"Save"}
                action={saveEmployee}
                isActionLoading={isUpdatingEmployee}
                employee={employee}
            />

            <DeleteEmployeeDialog
                modal={deleteModal}
                setModal={setDeleteModal}
                employee={employee}
            />
        </React.Fragment>
    )
}
