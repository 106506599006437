import { LinearProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Metrics, PieMetric } from "../events/eventDetail/metrics";
import empservice from "../services/corporate-employee.service";
import { PEOPLE_TYPE } from "./employeeList";

const EmployeeMetrics = ({ peopleType, partnerId, isHealth }) => {
  const [metricsComorbidities, setMetricsComorbidities] = useState([]);
  const [metricsAllergies, setMetricsAllergies] = useState([]);
  const [metricsLinkages, setMetricsLinkages] = useState([]);
  const [metricsGenders, setMetricsGenders] = useState([]);
  const [metricsAgeBrackets, setMetricsAgeBrackets] = useState([]);
  const [metricsVaccinations, setMetricsVaccinations] = useState([]);
  const [enableAnimate, setEnableAnimate] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchCounts() {
      let peopleParam = "EMPLOYEE";
      switch (peopleType) {
        case PEOPLE_TYPE.WORKERS:
          peopleParam = "EMPLOYEE";
          break;
        case PEOPLE_TYPE.DEPENDENTS:
          peopleParam = "DEPENDENT";
          break;
        case PEOPLE_TYPE.THIRDPARTY:
          peopleParam = "EXTERNAL";
          break;
        default:
          break;
      }

      let requestQueue = [
        empservice.fetchMetricsLinkages,
        empservice.fetchMetricsGender,
        empservice.fetchMetricsAgeBrackets,
      ];
      if (isHealth) {
        requestQueue = [
          ...requestQueue,
          empservice.fetchMetricsComorbidities,
          empservice.fetchMetricsAllergies,
          empservice.fetchMetricsVaccinations,
        ];
      }

      const requests = await Promise.allSettled(
        requestQueue.map(async (fn) => {
          const response = await fn(partnerId, peopleParam);
          return response;
        })
      );
      setLoading(false);
      requests.forEach((response) => {
        switch (response?.value?.tag) {
          case "comorbidities":
            setMetricsComorbidities(response?.value?.data ?? []);
            break;
          case "allergies":
            setMetricsAllergies(response?.value?.data ?? []);
            break;
          case "linkages":
            setMetricsLinkages(response?.value?.data ?? []);
            break;
          case "gender":
            setMetricsGenders(response?.value?.data ?? []);
            break;
          case "age_brackets":
            setMetricsAgeBrackets(response?.value?.data ?? []);
            break;
          case "vaccinations":
            setMetricsVaccinations(response?.value?.data ?? []);
            break;
          default:
            break;
        }
      });
      setEnableAnimate(true);
    }
    setEnableAnimate(false);
    fetchCounts();
  }, [partnerId, peopleType, isHealth]);

  return (
    <>
      {!isHealth && (
        <>
          <Grid item xs={4}>
            <PieMetric
              enableAnimate={enableAnimate}
              renderKey={loading ? 0 : Math.random()}
              metric={Metrics.RAWDATA}
              title="Linked Accounts"
              consolidatedCohortData={metricsLinkages}
            />
          </Grid>
          <Grid item xs={4}>
            <PieMetric
              enableAnimate={enableAnimate}
              renderKey={loading ? 0 : Math.random()}
              metric={Metrics.RAWDATA}
              title="Genders"
              consolidatedCohortData={metricsGenders}
            />
          </Grid>
          <Grid item xs={4}>
            <PieMetric
              enableAnimate={enableAnimate}
              renderKey={loading ? 0 : Math.random()}
              metric={Metrics.RAWDATA}
              title="Ages"
              consolidatedCohortData={metricsAgeBrackets}
            />
          </Grid>
        </>
      )}
      {isHealth && (
        <>
          <Grid item xs={4}>
            <PieMetric
              enableAnimate={enableAnimate}
              renderKey={loading ? 0 : Math.random()}
              metric={Metrics.RAWDATA}
              title="Comorbidities"
              consolidatedCohortData={metricsComorbidities}
            />
          </Grid>
          <Grid item xs={4}>
            <PieMetric
              enableAnimate={enableAnimate}
              renderKey={loading ? 0 : Math.random()}
              metric={Metrics.RAWDATA}
              title="Allergies"
              consolidatedCohortData={metricsAllergies}
            />
          </Grid>
          <Grid item xs={4}>
            <PieMetric
              enableAnimate={enableAnimate}
              renderKey={loading ? 0 : Math.random()}
              metric={Metrics.RAWDATA}
              title="Vaccinations"
              consolidatedCohortData={metricsVaccinations}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export { EmployeeMetrics };
