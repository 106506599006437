import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Input, Chip, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import reportService from '../services/report.service';
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    chip: {
        margin: 2,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        position: 'fixed'
    },
    select: {
        paddingBottom: 20
    }
}));

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '100%',
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const GenerateCovidTestResults = (props) => {
    const classes = useStyles();
    const [ loading, setLoading ] = useState(false);
    const [ startDate, setStartDate ] = useState(new Date());
    const [ endDate, setEndDate ] = useState(new Date());
    const [ testTypes, setTestTypes ] = useState([]);
    const [ selected, setSelected ] = useState([]);
    const [ error, setError ] = useState({ message: ''})
    const [ openError, setOpenError ] = useState(false);
    const theme = useTheme();


    const handleStartDateChange = date => {
        setStartDate(date);
    }

    const handleEndDateChange = date => {
        setEndDate(date);
    }

    const getTestTypes = (id) => {
        reportService.getTestTypes(id).then(
            response => {
                setTestTypes(response);
            }
        ).catch(error => {

        })
    }

    const handleChangeTestTypes = event => {
        console.log(event.target.value);
        setSelected(event.target.value);
    }

    useEffect(() => {
        getTestTypes(props.company);
    }, [])

    const downloadFile = event => {
        var params = {
            id: props.company,
            types: selected.map(s => s.id),
            start_date: moment(startDate).startOf('day').utc().toISOString(),
            end_date: moment(endDate).endOf('day').utc().toISOString(),
            disable_unique_owner:true,
        }

        setLoading(true);
        reportService.downloadCovidTestResultReport(params).then(
            response => {
                console.log(response);
                  var downloadFile = `COVID-Test-Results-Report-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
                  setLoading(false);
                  var file = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                  });
                  var saving = document.createElement('a');
                  saving.href = URL.createObjectURL(file);
                  saving.download = (
                    downloadFile
                  );
                  saving.click();
            }
        ).catch(error => {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message) setError({ message: error.response.data.message.en || ''})
            else setError({ message: 'Unable to export file'})
            setOpenError(true);
        })
    }


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ paddingLeft: 0}}>
                    <FormControl style={{ width: '100%'}} className={classes.formControl}>
                        <InputLabel id="test-types-label">
                            Test Type
                        </InputLabel>
                        <Select labelId="multiple-test-types-chip-label"
                            id="multiple-test-type-id"
                            multiple
                            value={selected}
                            classes={{
                                select: classes.select
                            }}
                            onChange={handleChangeTestTypes}
                            input={<Input id="select-multiple-test-type-chips" style={{ paddingBottom: 10 }}/>}
                            renderValue={(s) => (
                                <div className={classes.chips}>
                                  {s.map((value) => (
                                    <Chip key={value.label} label={value.label} className={classes.chip} />
                                  ))}
                                </div>
                              )}
                            MenuProps={MenuProps}>
                                 {testTypes.map((t, index) => (
                                    <MenuItem key={index} value={t} style={getStyles(t, selected, theme)}>
                                        { t.label }
                                    </MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    {/* <FormControl style={{ minWidth: 250, marginTop: 17}}>
                        <InputLabel id="attrib-name">Test Type</InputLabel>
                            <Select
                                labelId="attrib-value"
                                value={testType}
                                label="Test Type"
                                onChange={(ev) => setTestType(ev.target.value)}
                                >
                                {
                                   testTypes.map((val, index) => {
                                        return (
                                            <MenuItem key={index} value={val.id}>
                                                { val.label }
                                            </MenuItem>
                                        )
                                    })
                                }
                        </Select>
                    </FormControl> */}
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        value={startDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="End Date"
                        format="MM/dd/yyyy"
                        value={endDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change end date',
                        }}
                    />
                </Grid>
                <Grid item xs={12} align="center">
                    <Button variant="contained" color="primary" style={{ color: 'white'}} onClick={downloadFile}>
                        Download Report
                    </Button>
                </Grid>
            </Grid>
        </div>
        </MuiPickersUtilsProvider>
    )
}

export default GenerateCovidTestResults