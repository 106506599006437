import { FormControl, InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { getPeopleTypeByValue, PEOPLE_TYPE } from '../employeeList';

const useStyles = makeStyles((theme) => {
  return {}
})
const PeopleTypePicker = ({
  peopleType,
  onChangePeopleType = () => { },
}) => {
  const classes = useStyles()
  return (
    
      <TextField
        variant="outlined"
        select
        size="small"
        label=""
        value={getPeopleTypeByValue(peopleType)}
        onChange={event => {
          onChangePeopleType(event.target.value)
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">People List</InputAdornment>,
        }}
      >
        {Object.entries(PEOPLE_TYPE).map(([key, value]) =>
          <MenuItem value={key}>{value}</MenuItem>
        )}
      </TextField>

    
  )
}

export { PeopleTypePicker };
