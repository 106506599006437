import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import healthMetricsService from '../../services/health-metrics.service';
import { useState } from '../../utils/stateref';
import { PEOPLE_TYPE } from '../employeeList';
import { HealthRiskCounts } from './HealthRiskCounts';
import { Metrics } from './Metrics';
import { WorkerList } from './WorkerList';

import voca from 'voca';
import { useHistory } from 'react-router-dom';
import { PeopleTypePicker } from './PeopleTypePicker';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(4),
      textAlign: 'start',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 1440
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1920
      }
    },
    officeInfoTitle: {
      padding: 10
    },
    officeInfoSection: {
      padding: 10
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    textLeft: {
      textAlign: 'left'
    },
    marginSpacer: {
      marginRight: 10
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalContent: {
      backgroundColor: theme.palette.background.paper,
      border: 0,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
      width: '45%',
    },
    linkColor: {
      color: theme.palette.link.main
    },
    btnTextSize: {
      fontSize: '1.25rem'
    },
    rectangularChip: {
      padding: 5,
      borderRadius: 5,
      fontWeight: 700,
      fontSize: '0.75rem'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    headerAction: {
      '& > *': {
        marginLeft: theme.spacing(1)
      }
    }
  }
})
const HealthRiskView = ({ company, peopleType, ...props }) => {
  const classes = useStyles()
  const history = useHistory()
  const [metrics, setMetrics] = useState();

  const [filterParams, setFilterParams] = useState({});

  const [riskFilter, setRiskFilter] = useState("");

  useEffect(() => {
    setRiskFilter('')
  }, [peopleType]);
  const mapCovid = useCallback(
    ({ name, value }) => {
      return [
        { name: 'Tested', label: 'Tested', value: value?.valid_tests_count },
        { name: 'Not Tested', label: 'Not Tested', value: value?.health_workers_count - value?.valid_tests_count }
      ]
    }, []
  )

  const mapGeneric = useCallback(
    ({ name,label, value }) => {
      const ret = { key: name, name: voca.titleCase(unslugify(name)), label, value: value?.count }
      return ret
    },
    [],
  );

  const fetchMetrics = useCallback(
    async () => {
      const riskFilters = [
        { graph: 'Health Risk Assessment', label: 'Low risk', key: 'LOW', fn: healthMetricsService.getEmployeeHealthRiskCount, params: { ...filterParams, risk_factors: ["LOW"] } },
        { graph: 'Health Risk Assessment', label: 'Moderate risk', key: 'MODERATE', fn: healthMetricsService.getEmployeeHealthRiskCount, params: { ...filterParams, risk_factors: ["MODERATE"] } },
        { graph: 'Health Risk Assessment', label: 'High risk', key: 'HIGH', fn: healthMetricsService.getEmployeeHealthRiskCount, params: { ...filterParams, risk_factors: ["HIGH"] } },
        { graph: 'Health Risk Assessment', label: 'Unknown', key: 'UNKNOWN', fn: healthMetricsService.getEmployeeHealthRiskUnknownCount, params: { ...filterParams, risk_factors: [] } },
        { graph: 'At Risk Covid-19 Tested Workers', key: 'count', fn: healthMetricsService.getEmployeeCovidTestedCount, params: {} },
        // { graph: 'At Risk Workers Gender', key: 'male', fn: healthMetricsService.getEmployeeHealthRiskCount, params: { ...filterParams, risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'], gender: 'MALE' } },
        // { graph: 'At Risk Workers Gender', key: 'female', fn: healthMetricsService.getEmployeeHealthRiskCount, params: { ...filterParams, risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'], gender: 'FEMALE' } },
        // {
        //   graph: 'At Risk Workers Age Range', key: '0-17', fn: healthMetricsService.getEmployeeHealthRiskCount, params: {
        //     risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
        //     age_range_start: 0,
        //     age_range_end: 17
        //   }
        // }, {
        //   graph: 'At Risk Workers Age Range', key: '18-35', fn: healthMetricsService.getEmployeeHealthRiskCount, params: {
        //     risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
        //     age_range_start: 18,
        //     age_range_end: 35
        //   }
        // }, {
        //   graph: 'At Risk Workers Age Range', key: '36-64', fn: healthMetricsService.getEmployeeHealthRiskCount, params: {
        //     risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
        //     age_range_start: 36,
        //     age_range_end: 64
        //   }
        // }, {
        //   graph: 'At Risk Workers Age Range', key: '65+', fn: healthMetricsService.getEmployeeHealthRiskCount, params: {
        //     risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
        //     age_range_start: 65,
        //     age_range_end: 150
        //   }
        // }
      ]

      const results = await Promise.allSettled(
        riskFilters.map(async (riskFilter) => {
          let peopleParam = "EMPLOYEE";
          switch (peopleType) {
            case PEOPLE_TYPE.WORKERS:
              peopleParam = "EMPLOYEE";
              break;
            case PEOPLE_TYPE.DEPENDENTS:
              peopleParam = "DEPENDENT";
              break;
            case PEOPLE_TYPE.THIRDPARTY:
              peopleParam = "EXTERNAL";
              break;
            default:
              break;
          }
          const riskResult = await riskFilter.fn(company, { ...riskFilter.params, peopleType: peopleParam })
          return {
            name: riskFilter?.key,
            label: riskFilter?.label ?? voca.titleCase(riskFilter?.key),
            graph: riskFilter?.graph,
            value: riskResult
          }
        }
        )
      )
      const mappedResults = results.filter(s => s?.status === 'fulfilled').map(s => s?.value)

      const graphSeparation = mappedResults.reduce((acc, obj) => {
        acc[obj?.graph] = {}
        return acc
      }, {})

      mappedResults.forEach(m => {
        const graphItem = graphSeparation[m?.graph]
        let { graph, ...rest } = m;
        let appendix;
        if (m.graph === 'At Risk Covid-19 Tested Workers') {
          rest = mapCovid(rest)
          appendix = [...graphItem?.keys ?? [], ...rest]
        } else {
          rest = mapGeneric(rest)
          appendix = [...graphItem?.keys ?? [], rest]
        }

        graphSeparation[m?.graph].keys = appendix
        graphSeparation[m?.graph].labels = appendix.map(item => item.label)
        graphSeparation[m?.graph].values = appendix.map(item => item.value)
      })

      setMetrics(graphSeparation);
      console.log('index.jsx (105) # results', mappedResults);
    },
    [company, filterParams, mapCovid, mapGeneric, peopleType],
  );


  function unslugify(slug) {
    const result = slug.replace(/\_/g, " ");
    return result.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  useEffect(() => {
    fetchMetrics()
  }, [company, peopleType, fetchMetrics]);


  
  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h5" className={classes.textLeft} style={{ fontWeight: 'bold' }}>
          {peopleType} Health Risk
        </Typography>
        <PeopleTypePicker
          peopleType={peopleType}
          onChangePeopleType={pType => {
            history.replace(`/healthRisk/${pType}`)
          }}
        />
      </Box>

      <HealthRiskCounts
    metrics={metrics}
    peopleType={peopleType}
    riskFilter={riskFilter}
    onClickRisk={risk => setRiskFilter(risk)}
  />
      <Metrics metrics={metrics} company={company} peopleType={peopleType}/>
      <WorkerList company={company} peopleType={peopleType} risk={riskFilter}
      >
        
      </WorkerList>
    </div>
  )
}

export { HealthRiskView };
