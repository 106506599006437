import { Chip, InputLabel, TextField } from '@material-ui/core';
import { Avatar, Box, FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { PillSelect } from '@zennya/web-component-library/src/components/PillSelect/PillSelect';
import React from 'react';
import { useEffect } from 'react';
import { useState } from '../../utils/stateref';
import { getPeopleTypeByValue, PEOPLE_TYPE } from '../employeeList';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    chips: {
      display: 'flex',
      justifyContent: 'start',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      flexGrow: 3
    },
    peopleType: {
      flexGrow: 1,
      width: 'auto'
    },
    chip: {
      margin: theme.spacing(0.5),
    },

    chipRoot: {
      flexFlow: 'row-reverse',
      paddingRight: theme.spacing(1),
      boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
      backgroundColor: '#fff',
      border: 0,
      fontWeight: 'bolder',
      textTransform: 'uppercase'
    },
    chipAvatar: {
      width: 'auto !important',
      minWidth: '1em !important',
      borderRadius: '1em/50%',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
    label: {

    }

  }
})
const HealthRiskCounts = ({
  metrics = [],
  riskFilter,
  peopleType,
  onClickRisk = () => { }
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box component="ul" className={classes.chips}>
        <li key="all">
          <Chip
            variant="outlined"
            color={riskFilter === "" ? 'primary' : 'default'}
            label={`All ${peopleType}`}
            className={classes.chip}
            onClick={() => onClickRisk("")}
            classes={{
              root: classes.chipRoot,
              avatar: classes.chipAvatar,
              label: classes.label,
            }}
          />
        </li>
        {(metrics['Health Risk Assessment']?.keys ?? [])
          // .filter(t => t.key !== 'UNKNOWN')
          .map((risk, index) => {
            return <li key={index}>
              <Chip
                variant="outlined"
                color={riskFilter === risk?.key ? 'primary' : 'default'}
                label={risk.label}
                className={classes.chip}
                onClick={() => onClickRisk(risk.key)}
                avatar={<Avatar>{risk?.value}</Avatar>}
                classes={{
                  root: classes.chipRoot,
                  avatar: classes.chipAvatar,
                  label: classes.label,
                }}
              />
            </li>
          })}
      </Box>
    </Box>

  )
}

export { HealthRiskCounts };
